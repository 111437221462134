import React from "react";
import ReactResizeDetector from "react-resize-detector";
import {useTheme} from "utils/hooks";
import {useMessages} from "utils/messages";
import {THEME_DAY} from "constants/theme";
import styles from "./auth-layout.style";
import logoDay from "./images/logo.svg";
import logoNight from "./images/logo-black.svg";

const AuthLayout: React.FC = ({children}) => {
  const theme = useTheme();
  const style = styles[theme];
  const messages = useMessages();
  const logo = theme === THEME_DAY ? logoDay : logoNight;
  return (
    <ReactResizeDetector
      handleHeight
      render={({height}: {height: number}): React.ReactElement => (
        <div className={style.container} style={{height: `${height}px`}}>
          <div className={style.form}>
            <div className={style.logo_container}>
              <img alt="logo" src={logo} className={style.logo} />
            </div>
            <div className={style.greeting}>
              {messages.loginGreeting} <b>{messages.companyName}</b>
            </div>
            {children}
          </div>
          <div className={style.illustration_container}>
            <div className={style.illustration} />
          </div>
        </div>
      )}
    />
  );
};

export default AuthLayout;
