import {oc} from "ts-optchain";
import {RootState} from "reducers";
import {TEmployeeListItem} from "types/api/employee/employeeReadDict";

export const isEmployeeListInit = (state: RootState): boolean => state.employeeList.isInit;

export const isEmployeeListLoading = (state: RootState): boolean => state.employeeList.isLoading;

export const getFilter = (state: RootState): string => state.employeeList.filter;

export const getEmployeeCount = (state: RootState): number => state.employeeList.totalItems;

export const getEmployee = (state: RootState, index: number): TEmployeeListItem => {
  const {pageSize} = state.employeeList;
  const page = getEmployeePage(state, index);
  const pageIndex = index - pageSize * (page - 1);

  return oc(state).employeeList.pages[page][pageIndex]({} as TEmployeeListItem);
};

export const isEmployeeLoaded = (state: RootState, index: number): boolean => {
  const {pages} = state.employeeList;
  const page = getEmployeePage(state, index);

  if (state.employeeList.pageSize === 0) {
    return false;
  }
  return pages[page] !== undefined;
};

export const isEmployeeLoading = (state: RootState, index: number): boolean => {
  const page = getEmployeePage(state, index);
  return state.employeeList.loading[page] === true;
};

export const getEmployeePage = (state: RootState, index: number): number => {
  const {pageSize} = state.employeeList;
  return Math.ceil((index + 1) / pageSize);
};
