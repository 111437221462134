import React, {MouseEventHandler} from "react";
import {messagesUrl} from "utils/url-builder";
import {TEmployeeInstance} from "types/api/employee/employeeInstance";
import ViewCard from "components/common/view-card";
import {Icon} from "components/ui/element/icon";
import FieldLabel from "components/ui/element/field-label";
import FieldText from "components/ui/element/field-text";
import FieldInteger from "components/ui/element/field-integer";
import FieldPhone from "components/ui/element/field-phone";
import Divider from "components/ui/element/divider";
import {Avatar} from "components/common/avatar";
import {Link} from "react-router-dom";
import {useMessages} from "utils/messages";
import {useTheme} from "utils/hooks";
import {noop} from "lodash-es";
import {TAsyncResult} from "api/common";
import styles from "./employee-view-card-style";

export type TEmployeeViewCardProps = {
  oid: number;
  hideButtons?: boolean;
  onClickLinkOutside?: MouseEventHandler<HTMLAnchorElement>;
} & TAsyncResult<TEmployeeInstance> &
  typeof EmployeeViewCardDefaultProps;
const EmployeeViewCardDefaultProps = {
  data: {} as TEmployeeInstance,
};

const EmployeeViewCard = (props: TEmployeeViewCardProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];

  const messages = useMessages();
  const {oid, data, hideButtons, onClickLinkOutside = noop} = props;

  let scheduleOnToday = data.StatusName;
  if (scheduleOnToday && data.NearestSheduleInfo) {
    scheduleOnToday = `${scheduleOnToday} (${data.NearestSheduleInfo})`;
  } else {
    scheduleOnToday = data.NearestSheduleInfo;
  }

  return (
    <>
      <ViewCard id={oid} hideButtons={hideButtons}>
        <div className={style.container}>
          <div className={style.header}>
            <div>
              <Avatar oid={oid} medium boxy />
            </div>
            <div className={style.info}>
              <div>
                <span className={style.middleName}>{data.MiddleName}</span>
              </div>
              <div>
                <span className={style.status}>
                  <span style={{color: data.StatusColorHEX}}>
                    <Icon name="circle" />
                  </span>
                  {scheduleOnToday}
                </span>
              </div>
              <Divider className={style.upperDivider} />
              <span className={style.contactActions}>
                {/* <Icon name="whatsapp square" /> */}
                {data.Email && (
                  <a href={`mailto:${data.Email}`}>
                    <Icon name="mail" />
                  </a>
                )}
                <Link to={messagesUrl(data.UserID)} onClick={onClickLinkOutside}>
                  <Icon name="chat" />
                </Link>
              </span>
              <Divider className={style.bottomDivider} />
            </div>
          </div>

          <FieldLabel label={messages.employeeTerritoryShortName} display={!!data.TerritoryShortName}>
            <FieldText value={data.TerritoryShortName} />
          </FieldLabel>

          <FieldLabel label={messages.employeeDepartShortName} display={!!data.DepartOIDShortName}>
            <FieldText value={data.DepartOIDShortName} />
          </FieldLabel>

          <FieldLabel label={messages.employeeBusyPost} display={!!data.PositionName}>
            <FieldText value={data.PositionName} />
          </FieldLabel>

          <FieldLabel label={messages.employeeBirthdate} display={!!data.BirthDate}>
            <FieldText value={data.BirthDate} />
          </FieldLabel>

          <FieldLabel label={messages.employeeBusyExperience} display={!!data.Seniority}>
            <FieldInteger value={data.Seniority} />
          </FieldLabel>

          {data.Phones && <Divider />}

          {data.Phones &&
            data.Phones.map((phone) => {
              let phoneText = phone.Number;
              if (phone.Extension) {
                phoneText = phoneText
                  ? `${phoneText}, ${messages.extraPhoneNumber} ${phone.Extension}`
                  : phone.Extension;
              }
              return (
                <FieldLabel key={phoneText} label={phone.TypeName} display={!!phoneText}>
                  <FieldPhone value={phoneText} code={phone.TypeCode} />
                </FieldLabel>
              );
            })}
        </div>
      </ViewCard>
    </>
  );
};
EmployeeViewCard.defaultProps = EmployeeViewCardDefaultProps;

export default EmployeeViewCard;
