/* eslint-disable import/prefer-default-export */

import {
  chatbotUrl,
  employeeListUrl,
  messagesUrl,
  taskListUrl,
  farmOrderCreateUrl,
  farmOrderActiveListUrl,
  farmOrderArchiveListUrl,
  farmStoreListUrl,
  farmMovementUrl,
  clientsUrl,
} from "utils/url-builder";
import {SemanticICONS} from "semantic-ui-react";

export type TMenuItem = {
  userMenuId?: string;
  url: string;
  title: string;
  subtitle?: string;
  icon?: SemanticICONS;
};

export const menu: TMenuItem[] = [
  {
    url: taskListUrl(),
    title: "menuTaskListActual",
    subtitle: "menuTaskListActualSubtitle",
    icon: "tasks",
    userMenuId: "tasks",
  },
  {
    url: employeeListUrl(),
    title: "menuEmployeeList",
    subtitle: "menuEmployeeListSubtitle",
    icon: "users",
    userMenuId: "common/contacts",
  },
  {
    url: messagesUrl(),
    title: "menuMessages",
    subtitle: "menuMessagesSubtitle",
    icon: "chat",
    userMenuId: "common/messages",
  },
  {
    url: farmOrderCreateUrl(),
    title: "menuFarmOrderCreate",
    subtitle: "menuFarmOrderCreateSubtitle",
    icon: "hand pointer",
    userMenuId: "farm/form/create",
  },
  {
    url: farmOrderActiveListUrl(),
    title: "menuFarmOrderActive",
    subtitle: "menuFarmOrderActiveSubtitle",
    icon: "check circle",
    userMenuId: "farm/grid/act",
  },
  {
    url: farmOrderArchiveListUrl(),
    title: "menuFarmOrderArchive",
    subtitle: "menuFarmOrderArchiveSubtitle",
    icon: "archive",
    userMenuId: "farm/grid/arc",
  },
  {
    url: farmStoreListUrl(),
    title: "menuFarmStore",
    subtitle: "menuFarmStoreSubtitle",
    icon: "warehouse",
    userMenuId: "farm/storage/list",
  },
  {
    url: farmMovementUrl(),
    title: "menuFarmMovement",
    subtitle: "menuFarmMovementSubtitle",
    icon: "exchange",
    userMenuId: "farm/move",
  },
  {
    url: chatbotUrl(),
    title: "menuChatbot",
    subtitle: "menuChatbotSubtitle",
    icon: "whatsapp",
    userMenuId: "common/chatbot",
  },
  {
    url: clientsUrl(),
    title: "menuClients",
    icon: "users",
  },
  {url: "/logout", title: "menuLogout", icon: "log out"},
];
