import React from "react";
import cn from "classnames";
import {GridColumn as BaseGridColumn, StrictGridColumnProps} from "semantic-ui-react";
import {useTheme} from "utils/hooks";
import styles from "./grid-column-style";

export type TGridColumnProps = StrictGridColumnProps;

const GridColumn = (props: TGridColumnProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];
  const {className, ...rest} = props;

  return <BaseGridColumn className={cn(style.control, className)} {...rest} />;
};

export default GridColumn;
