import React, {useState, useRef, useEffect} from "react";
import {useParams} from "react-router-dom";
// import Main from "layouts/main";
import {InlineLoader} from "components/ui/element/loader";
import Grid from "components/ui/element/grid";

const EmployeeCard = React.lazy(() => import("components/employee-card"));

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    saveForm: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formSaved: any;
  }
}

window.saveForm = (): void => {
  const event = new Event("saveFormEvent");
  window.dispatchEvent(event);
};

const Employee = (): React.ReactElement => {
  const {id} = useParams();
  const [data, setData] = useState({});
  const submitRef = useRef<HTMLButtonElement>(null);
  const phoneTypes = [
    {id: "mobile", caption: "Мобильный"},
    {id: "work", caption: "Рабочий"},
    {id: "home", caption: "Домашний"},
  ];
  const sailTypes = [
    {id: 0, caption: "type 0"},
    {id: 1, caption: "type 1"},
    {id: 2, caption: "type 2"},
  ];

  const handleSubmit = (values: object): void => {
    setData(values);
    if (window.formSaved) {
      window.formSaved(values);
    }
  };
  const handleSaveForm = (): void => {
    if (submitRef.current) {
      submitRef.current.click();
    }
  };

  useEffect(() => {
    window.addEventListener("saveFormEvent", handleSaveForm);
    return (): void => window.removeEventListener("saveFormEvent", handleSaveForm);
  }, []);

  return (
    // <Main>
    <Grid padded>
      <Grid.Column width={8}>
        <React.Suspense fallback={<InlineLoader />}>
          <EmployeeCard
            id={Number(id)}
            submitRef={submitRef}
            phoneTypes={phoneTypes}
            sailTypes={sailTypes}
            onSubmit={handleSubmit}
          />
        </React.Suspense>
      </Grid.Column>
      <Grid.Column width={8}>
        Submit results:
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </Grid.Column>
    </Grid>
    // </Main>
  );
};

export default Employee;
