import React from "react";
import {InlineLoader} from "components/ui/element/loader";

const EmployeeList = React.lazy(() => import("components/section/employee/employee-list"));

const EmployeeListRoute = (): React.ReactElement => {
  return (
    <React.Suspense fallback={<InlineLoader />}>
      <EmployeeList />
    </React.Suspense>
  );
};

export default EmployeeListRoute;
