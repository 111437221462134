import React, {useEffect} from "react";
import {Route, Redirect, RouteProps, useLocation, matchPath} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {menu} from "components/section/menu";
import {getIsLoading, getPageAccess} from "selectors/access";
import {fetchPageAccess} from "actions/thunk";
import {RootState} from "reducers";
import {Loader} from "components/ui/element/loader";
import Forbidden from "components/ui/element/forbidden";
import Main from "layouts/main";

export interface ProtectedRouteProps extends RouteProps {
  allowed: boolean;
  redirectToUrl: string;
  userMenuId?: string;
}

const useMenuId = (userMenuId: string | undefined): string | undefined => {
  const location = useLocation();
  let menuId = userMenuId;
  if (!menuId) {
    const foundMenuItem = menu.find((item) => matchPath(location.pathname, {path: item.url}));
    if (foundMenuItem) {
      menuId = foundMenuItem.userMenuId;
    }
  }
  return menuId;
};

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({allowed, redirectToUrl, userMenuId, ...rest}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const menuId = useMenuId(userMenuId);
  const hasAccess = useSelector((state: RootState) => getPageAccess(state, menuId));

  useEffect(() => {
    if (!menuId || !allowed || hasAccess !== undefined || isLoading) {
      return;
    }
    dispatch(fetchPageAccess(menuId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuId]);

  if (!allowed) {
    return <Redirect to={redirectToUrl} />;
  }
  if (isLoading || hasAccess === undefined) {
    return (
      <Main>
        <Loader />
      </Main>
    );
  }
  if (!hasAccess) {
    return (
      <Main>
        <Forbidden />
      </Main>
    );
  }

  return <Route {...rest} />;
};

export default ProtectedRoute;
