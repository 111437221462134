import React from "react";
import {useTheme} from "utils/hooks";
import {useMessages} from "utils/messages";
import styles from "./forbidden-style";

const Forbidden = (): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];
  const {forbidden} = useMessages();

  return (
    <div className={style.container}>
      <span>{forbidden}</span>
    </div>
  );
};

export default Forbidden;
