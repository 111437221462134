import {combineReducers} from "redux";
import {connectRouter} from "connected-react-router";
import {History} from "history";
import auth from "./auth";
import taskList from "./task-list";
import employeeList from "./employee-list";
import ui from "./ui";
import taskFileTypeList from "./task-file-type-list";
import access from "./access";

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),

    auth,
    access,
    taskList,
    employeeList,
    ui,
    taskFileTypeList,
  });

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>;
export default createRootReducer;
