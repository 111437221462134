const DESKTOP_TO_UI_EVENT_NAME = "desktopToWebEvent";
const UI_TO_DESKTOP_EVENT_NAME = "webToDesktopEvent";

export type TData = {[key: string]: string | number | boolean} | null;
type THandler = (event: string, data: TData, id?: string, replyId?: string) => void;

const handlersMap = new Map();

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const handlerWrapper = (handler: THandler) =>
  ((e: CustomEvent) => {
    const {
      detail: {event, id, reply_id: replyId, data},
    } = e;
    handler(event as string, data as TData, id, replyId);
  }) as EventListener;

export const addListener = (handler: THandler): void => {
  const h = handlerWrapper(handler);
  handlersMap.set(handler, h);
  window.addEventListener(DESKTOP_TO_UI_EVENT_NAME, h);
};

export const removeListener = (handler: THandler): void => {
  window.removeEventListener(DESKTOP_TO_UI_EVENT_NAME, handlersMap.get(handler));
  handlersMap.delete(handler);
};

export const dispatchEvent = (event: string, data: TData, id?: string, replyId?: string): void => {
  window.dispatchEvent(
    new CustomEvent(UI_TO_DESKTOP_EVENT_NAME, {
      detail: {
        event,
        data,
        id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        reply_id: replyId,
      },
    })
  );
};

/* eslint-disable no-shadow */
export const dispatchEventAsync = async (event: string, data: TData, id?: string, replyId?: string): Promise<TData> => {
  return new Promise((resolve, reject) => {
    const handleEnd = (data: TData): void => {
      removeListener(handler);
      resolve(data);
    };

    const handler = (event: string, data: TData, eventId?: string, eventReplyId?: string): void => {
      if (id === eventReplyId) {
        handleEnd(data);
      }
    };

    addListener(handler);
    dispatchEvent(event, data, id, replyId);
  });
};
/* eslint-enable no-shadow */

const responsesMap = new Map();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const putResponse = (eventId: string, data: any): void => {
  responsesMap.set(eventId, data);
};

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    desktopToWebResponse: any;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
window.desktopToWebResponse = (eventId: string): any | null => {
  const result = responsesMap.get(eventId);
  if (!result) {
    return null;
  }
  responsesMap.delete(eventId);

  return result;
};
