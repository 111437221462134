import React, {useCallback, useContext, useMemo} from "react";
import CN from "classnames";
import {Button, Icon} from "semantic-ui-react";
import {useTheme} from "utils/hooks";
import SearchInput from "components/ui/control/search-input";
import {useSelector} from "react-redux";
import {getTaskGroups, getTaskGroupStatuses} from "selectors/task";
import {includes} from "lodash-es";
import commonStyles from "styles/common-styles";
import {RootState} from "reducers";
import {TaskFilterContext} from "./task-filter-context";
import styles from "./task-list-filter-style";

type TTaskListFilterProps = {
  className?: string;
} & typeof TaskListFilterDefaultProps;
const TaskListFilterDefaultProps = {};

const TaskFilterInput = (): React.ReactElement => {
  const [state, {setFilter}] = useContext(TaskFilterContext);
  const filterValue = useMemo(() => state.filter, [state.filter]);
  const handleFilter = useCallback((value: string): void => setFilter({filter: value}), [setFilter]);
  const theme = useTheme();
  const style = styles[theme];

  return useMemo(() => <SearchInput className={style.input} value={filterValue} onChange={handleFilter} />, [
    style,
    filterValue,
    handleFilter,
  ]);
};

const TaskFilterStatuses = (): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];
  const common = commonStyles[theme];
  const [state, {setStatus}] = useContext(TaskFilterContext);
  const selector = useMemo(() => getTaskGroupStatuses, []);
  const statuses = useSelector((rootState: RootState) => selector(rootState, state.groupName));
  return useMemo(() => {
    if (!state.showFilters) {
      return <></>;
    }
    const handleSetStatus = (status: number) => (): void => setStatus(state.status !== status ? status : undefined);
    return (
      <>
        <br />
        {statuses.map(({statusName, statusOID, statusColorHEX, statusTextColorHEX, ...rest}) => (
          <Button
            key={statusOID}
            className={CN(common.toggleBtn, style.groupButton, {
              [common.activeToggleBtn]: state.status === statusOID,
            })}
            onClick={handleSetStatus(statusOID)}
            style={
              state.status === statusOID
                ? undefined
                : {
                    background: statusColorHEX ? `#${statusColorHEX}` : undefined,
                    color: statusTextColorHEX ? `#${statusTextColorHEX}` : undefined,
                  }
            }
          >
            <span>{statusName}</span>
          </Button>
        ))}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statuses, state.showFilters, state.status, setStatus]);
};

const TaskFilterGroups = (): React.ReactElement => {
  const [state, {setGroup}] = useContext(TaskFilterContext);
  const groups = useSelector(getTaskGroups);
  const theme = useTheme();
  const style = styles[theme];
  const common = commonStyles[theme];

  return useMemo(() => {
    if (!state.showFilters) {
      return <></>;
    }
    const handleSetGroup = (groupName: string) => (): void => setGroup(groupName);
    return (
      <>
        {state.groupName &&
        includes(
          groups.map(({name}) => name),
          state.groupName
        ) ? (
          <Button
            className={CN(common.toggleBtn, style.groupButton, common.activeToggleBtn)}
            onClick={handleSetGroup("")}
          >
            <span>{state.groupName}</span>
            <Icon name="trash alternate" />
          </Button>
        ) : (
          groups.map(({name, count}) => (
            <Button
              key={name}
              className={CN(common.toggleBtn, style.groupButton, {[common.activeToggleBtn]: state.groupName === name})}
              onClick={handleSetGroup(name)}
            >
              {name} ({count})
            </Button>
          ))
        )}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups, state.showFilters, state.groupName, setGroup]);
};

const TaskFilterButton = (): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];
  const [state, {toggleFilter}] = useContext(TaskFilterContext);

  const isApplied = useMemo(() => state.groupName || state.status, [state.groupName, state.status]);

  return useMemo(
    () => (
      <button
        type="button"
        className={CN(style.filterButton, {[style.activeFilter]: state.showFilters, [style.appliedFilter]: isApplied})}
        onClick={toggleFilter}
      >
        <Icon name="filter" />
      </button>
    ),
    [style, state.showFilters, toggleFilter, isApplied]
  );
};

const TaskListFilter = ({className}: TTaskListFilterProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];

  return (
    <div className={CN(style.container, className)}>
      <TaskFilterInput />
      <TaskFilterButton />
      <TaskFilterGroups />
      <TaskFilterStatuses />
    </div>
  );
};
TaskListFilter.defaultProps = TaskListFilterDefaultProps;

export default TaskListFilter;
