import React from "react";
import {Tab as BaseTab, StrictTabProps} from "semantic-ui-react";
import {useTheme} from "utils/hooks";
import styles from "./tab-style";
import TabPane from "./tab-pane";

export type TTabProps = StrictTabProps;

const Tab = (props: TTabProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];

  return <BaseTab className={style.control} {...props} />;
};
Tab.Pane = TabPane;

export default Tab;
