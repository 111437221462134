import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {tokenExpired} from "actions";

const Logout = (): React.ReactElement => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(tokenExpired());
  });

  return <></>;
};

export default Logout;
