import React from "react";
import {InlineLoader} from "components/ui/element/loader";
import Segment from "components/ui/element/segment";

const TaskCurrentListContainer = React.lazy(() =>
  import("components/task/task-list-actual/task-current-list-container")
);

const TaskCurrentListRoute = (): React.ReactElement => (
  <React.Suspense fallback={<InlineLoader />}>
    <Segment page>
      <TaskCurrentListContainer />
    </Segment>
  </React.Suspense>
);

export default TaskCurrentListRoute;
