import React, {useMemo} from "react";
import ButtonMenu from "components/section/menu/button-menu";
import {menu} from "components/section/menu";
import {useTheme} from "utils/hooks";
import {useSelector} from "react-redux";
import {getAllUserMenuItems} from "selectors/auth";
import {includes} from "lodash-es";
import styles from "./sidebar-style";

const Sidebar: React.FC = ({children}) => {
  const theme = useTheme();
  const style = styles[theme];
  const userMenuItems = useSelector(getAllUserMenuItems);
  const visibleMenu = useMemo(
    () => menu.filter((item) => !item.userMenuId || includes(userMenuItems, item.userMenuId)),
    [userMenuItems]
  );
  return (
    <div className={style.container}>
      <ButtonMenu menu={visibleMenu} />
    </div>
  );
};

export default Sidebar;
