// eslint-disable-next-line import/prefer-default-export
export const API_URL_BASE_PATH = process.env.REACT_APP_API_URL_BASE_PATH;
export const API_URL = process.env.API_URL_BASE_PATH;
export const API_BASIC_AUTH_USERNAME = process.env.REACT_APP_API_BASIC_AUTH_USERNAME;
export const API_BASIC_AUTH_PASSWORD = process.env.REACT_APP_API_BASIC_AUTH_PASSWORD;

export const SELECT_NULL_VALUE = "Не задано";

export const ROOT_PARTNER_URL = process.env.ROOT_PARTNER_URL || "https://partnertest.ma.ru";

export const PARTNER_V1_PATH = "/partner-v1";

export const LOCALE = "ru";

export const TEST_API_URL = process.env.REACT_APP_STUB_API_URL_BASE_PATH || "https://apitest.ma.ru";
