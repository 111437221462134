import React, {createContext, Dispatch, SetStateAction, useContext, useMemo, useState} from "react";
import {useWindowSize} from "react-use";
import {TLeftSidebar, TRightSidebar} from "types/ui";
import {useSelector} from "react-redux";
import {getLeftSidebar, getRightSidebar} from "selectors/ui";

const DisplayTypeContext = createContext<TSidebarMobileInterface | undefined>(undefined);

export type TSidebarMobileState = {
  left?: TLeftSidebar;
  right?: TRightSidebar;
};

interface TSidebarMobileInterface {
  sidebarMobileState: TSidebarMobileState;
  setSidebarMobileState: Dispatch<SetStateAction<TSidebarMobileState>>;
  isMobile: boolean | undefined;
}

export const useIsMobile = (): TSidebarMobileInterface => {
  const {isMobile, sidebarMobileState, setSidebarMobileState} =
    useContext(DisplayTypeContext) || ({} as TSidebarMobileInterface);
  return useMemo(
    () => ({
      isMobile,
      sidebarMobileState,
      setSidebarMobileState,
    }),
    [isMobile, sidebarMobileState, setSidebarMobileState]
  );
};

export const useLeftSidebarType = (): TLeftSidebar | undefined => {
  const leftSidebar = useSelector(getLeftSidebar);
  const {isMobile, sidebarMobileState} = useIsMobile();
  return !isMobile ? leftSidebar : sidebarMobileState.left;
};

export const useRightSidebarType = (): TRightSidebar | undefined => {
  const rightSidebar = useSelector(getRightSidebar);
  const {isMobile, sidebarMobileState} = useIsMobile();
  return !isMobile ? rightSidebar : sidebarMobileState.right;
};

export const DisplayTypeContextProvider: React.FC = ({children}): React.ReactElement => {
  const {width} = useWindowSize();
  const [sidebarMobileState, setSidebarMobileState] = useState<TSidebarMobileState>({});
  const isMobile = width < 1000;
  return (
    <DisplayTypeContext.Provider value={{isMobile, sidebarMobileState, setSidebarMobileState}}>
      {children}
    </DisplayTypeContext.Provider>
  );
};
