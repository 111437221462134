import produce from "immer";
import {Action, createReducer} from "typesafe-actions";
import {pageAccessRequested, pageAccessFailed, pageAccessReceived} from "actions";

interface TPageAccessState {
  readonly data: {
    [key: string]: boolean;
  };
  readonly loaded: boolean;
  readonly loading: boolean;
}

const initialState: TPageAccessState = {
  data: {},
  loaded: false,
  loading: false,
};

export default createReducer<TPageAccessState, Action>(initialState)
  .handleAction(pageAccessRequested, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    })
  )
  .handleAction(pageAccessFailed, (state, {payload}) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.loaded = true;
      draft.data[payload] = false;
    })
  )
  .handleAction(pageAccessReceived, (state, {payload}) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.loaded = true;
      draft.data[payload] = true;
    })
  );
