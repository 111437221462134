import React from "react";
import {useTheme} from "utils/hooks";
import styles from "./field-integer-style";

type TFieldIntegerProps = {
  name?: string;
  label?: string;
  value?: number;
} & typeof FieldIntegerDefaultProps;
const FieldIntegerDefaultProps = {};

const FieldInteger = ({name, label, value}: TFieldIntegerProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];

  if (label === undefined) {
    return <span className={style.value}>{value}</span>;
  }

  return (
    <div className={style.container}>
      <span>{label}</span>
      <span>{value}</span>
    </div>
  );
};
FieldInteger.defaultProps = FieldIntegerDefaultProps;

export default FieldInteger;
