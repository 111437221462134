import produce from "immer";
import {Action, createReducer} from "typesafe-actions";
import {TEmployeeListItem} from "types/api/employee/employeeReadDict";
import {employeeListReceived, employeeListRequested, employeeFilterChanged} from "actions";

type EmployeeListState = Readonly<{
  pages: {[key: number]: TEmployeeListItem[]};
  loading: {[key: number]: boolean};
  filter: string;
  pageSize: number;
  totalItems: number;
  isInit: boolean;
  isLoading: boolean;
}>;

const initialState: EmployeeListState = {
  pages: {},
  loading: {},
  filter: "",
  pageSize: 0,
  totalItems: 0,
  isInit: false,
  isLoading: false,
};

export default createReducer<EmployeeListState, Action>(initialState)
  .handleAction(employeeListRequested, (state, {payload}) => {
    const {page} = payload;
    return produce(state, (draft) => {
      if (page === 1) {
        draft.isLoading = true;
      }
      draft.loading[page] = true;
    });
  })
  .handleAction(employeeListReceived, (state, {payload: {data, reset}}) => {
    const {Items: employees, Nav: nav} = data;
    return produce(state, (draft) => {
      if (reset) {
        draft.pages = {};
        draft.loading = {};
      }
      draft.pages[nav.CurrentPage] = employees;
      draft.loading[nav.CurrentPage] = false;
      draft.isInit = true;
      draft.isLoading = false;
      draft.pageSize = nav.PageSize;
      draft.totalItems = nav.TotalItems;
    });
  })
  .handleAction(employeeFilterChanged, (state, {payload}) => {
    return produce(state, (draft) => {
      const {filter} = payload;
      draft.filter = filter;
    });
  });
