import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {ConnectedRouter} from "connected-react-router";
import {LastLocationProvider} from "react-router-last-location";
import "./index.css";
import configureStore, {history} from "store/configure-store";
import {PersistGate} from "redux-persist/integration/react";
import {MessagesProvider} from "utils/messages";
import {ModalProvider} from "components/ui/modal";
import {DisplayTypeContextProvider} from "layouts/main/context";
import {Loader} from "components/ui/element/loader";
import App from "./app";
import * as serviceWorker from "./service-worker";
import AppDataLoader from "./appDataLoader";

const [store, persistor] = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<Loader />} persistor={persistor}>
      <MessagesProvider>
        <ConnectedRouter history={history}>
          <LastLocationProvider>
            <AppDataLoader>
              <DisplayTypeContextProvider>
                <ModalProvider>
                  <App />
                </ModalProvider>
              </DisplayTypeContextProvider>
            </AppDataLoader>
          </LastLocationProvider>
        </ConnectedRouter>
      </MessagesProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
