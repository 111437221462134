import React, {useCallback} from "react";
import {employeeViewUrl} from "utils/url-builder";
import {Link} from "react-router-dom";
import CN from "classnames";
import {useTheme} from "utils/hooks";
import {useDialog} from "components/ui/modal";
import EmployeeViewDialog, {TEmployeeComponentName} from "components/common/employee-view-dialog/employee-view-dialog";
import styles from "./avatar-style";
import Avatar from "./avatar";

export type TUserNameProps = {
  oid?: number;
  name?: string;
  className?: string;
  asUrl?: boolean;
  medium?: boolean;
  showEmployeeDialog?: boolean;
  employeeComponentName?: TEmployeeComponentName;
} & typeof TUserNameDefaultProps;
const TUserNameDefaultProps = {
  showEmployeeDialog: true,
  employeeComponentName: "EmployeeViewCard",
};

const UserName = (props: TUserNameProps): React.ReactElement => {
  const {oid, name, className, asUrl, medium, showEmployeeDialog, employeeComponentName} = props;
  const theme = useTheme();
  const style = styles[theme];
  const {showDialog} = useDialog();
  const handleClick = useCallback(
    (event) => {
      if (!showEmployeeDialog) {
        return true;
      }
      event.preventDefault();
      if (oid) {
        showDialog(() => <EmployeeViewDialog oid={oid} componentName={employeeComponentName} />);
      }
      return false;
    },
    [oid, showDialog, showEmployeeDialog, employeeComponentName]
  );

  if (asUrl) {
    return (
      <span className={CN(style.userName, className, medium ? style.medium : style.standard)}>
        <Link to={employeeViewUrl(oid)} onClick={handleClick}>
          {oid && <Avatar oid={oid} />}
          {name && <span>{name}</span>}
        </Link>
      </span>
    );
  }

  return (
    <span className={CN(style.userName, className, medium ? style.medium : style.standard)}>
      {oid && <Avatar oid={oid} />}
      {name && <span>{name}</span>}
    </span>
  );
};
UserName.defaultProps = TUserNameDefaultProps;

export default UserName;
