import {Modal, ModalProps} from "semantic-ui-react";
import React from "react";
import {useTheme} from "utils/hooks";
import CN from "classnames";
import {useDialog} from "components/ui/modal/index";
import styles from "./base-dialog-style";

export type TDialogProps = ModalProps;

const BaseDialog = ({className, ...rest}: TDialogProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];
  const {hideDialog} = useDialog();
  return (
    <Modal
      open
      className={CN(style.container, className)}
      {...rest}
      onClose={hideDialog}
      closeOnEscape
      closeOnDimmerClick
    />
  );
};

export default BaseDialog;
