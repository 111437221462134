import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {getErrorText} from "selectors/auth";
import LoginForm, {AuthParams} from "components/login-form";
import {fetchToken} from "actions/thunk";

const Login = (): React.ReactElement => {
  const dispatch = useDispatch();
  const errorText = useSelector(getErrorText);

  const handleLogin = ({login, password}: AuthParams): void => {
    dispatch(fetchToken(login, password));
  };
  return <LoginForm onSubmit={handleLogin} errorText={errorText} />;
};

export default Login;
