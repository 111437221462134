import React from "react";
import {useTheme} from "utils/hooks";
import styles from "./field-text-style";

type TFieldTextProps = {
  name?: string;
  label?: string;
  value?: string;
} & typeof FieldTextDefaultProps;
const FieldTextDefaultProps = {
  value: "",
};

const FieldText = ({name, label, value}: TFieldTextProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];

  if (label === undefined) {
    return <span className={style.value}>{value}</span>;
  }

  return (
    <div className={style.container}>
      <span>{label}</span>
      <span>{value}</span>
    </div>
  );
};
FieldText.defaultProps = FieldTextDefaultProps;

export default FieldText;
