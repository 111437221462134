/* eslint-disable import/prefer-default-export */

import React from "react";
import cn from "classnames";
import {Icon as IconBase, StrictIconProps} from "semantic-ui-react";
import {useTheme} from "utils/hooks";
import styles from "./icon-style";

export type TIconProps = StrictIconProps;

export const Icon = (props: TIconProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];
  const {className, ...rest} = props;

  return <IconBase className={cn(style.control, className)} {...rest} />;
};
