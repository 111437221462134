import React from "react";
import {InlineLoader} from "components/ui/element/loader";

const IframePage = React.lazy(() => import("components/iframe-page"));

const IframeRoute = (): React.ReactElement => {
  return (
    <React.Suspense fallback={<InlineLoader />}>
      <IframePage />
    </React.Suspense>
  );
};

export default IframeRoute;
