/* eslint-disable import/prefer-default-export */

import React from "react";
import {Button as ButtonBase, StrictButtonProps, ButtonProps} from "semantic-ui-react";
import {useTheme} from "utils/hooks";
import styles from "./button-style";

export type TButtonProps = StrictButtonProps & ButtonProps;

export const Button = (props: TButtonProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];

  return <ButtonBase className={style.control} {...props} />;
};
