/* eslint-disable import/prefer-default-export */

import React from "react";
import cn from "classnames";
import {Header as HeaderBase, StrictHeaderProps} from "semantic-ui-react";
import {useTheme} from "utils/hooks";
import styles from "./header-style";
import {HeaderContent} from "./header-content";
import {HeaderSubheader} from "./header-subheader";

export type THeaderProps = StrictHeaderProps;

export const Header = (props: THeaderProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];
  const {className, ...rest} = props;

  return <HeaderBase className={cn(style.control, className)} {...rest} />;
};

Header.Content = HeaderContent;
Header.Subheader = HeaderSubheader;
