/* eslint-disable import/prefer-default-export */

import React from "react";
import cn from "classnames";
import {Menu as MenuBase, StrictMenuProps} from "semantic-ui-react";
import {useTheme} from "utils/hooks";
import styles from "./menu-style";
import {MenuItem} from "./menu-item";

export type TMenuProps = StrictMenuProps;

export const Menu = (props: TMenuProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];
  const {className, ...rest} = props;

  return <MenuBase className={cn(style.control, className)} {...rest} />;
};

Menu.Item = MenuItem;
