import React from "react";
import CN from "classnames";
import {useTheme} from "utils/hooks";
import {TTask} from "types/api/task";
import {Link} from "react-router-dom";
import {taskViewUrl} from "utils/url-builder";
import {UserName} from "components/common/avatar";
import DateFormat from "components/ui/element/date-format";
import styles from "./task-list-actual-item-style";

type TTaskListActualItemProps = {
  task: TTask;
  className?: string;
};

const TaskListActualItem = ({task, className}: TTaskListActualItemProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];

  const statusBgColor = task.statusColorHEX ? `#${task.statusColorHEX}` : undefined;
  const statusTextColor = task.statusTextColorHEX ? `#${task.statusTextColorHEX}` : undefined;
  return (
    <Link to={taskViewUrl(task.oid)} className={CN(style.container, className)}>
      <div className={style.inner}>
        <span className={style.taskId}>№{task.id}</span>
        <DateFormat className={style.date} date={task.dateCreate} />
        <span className={style.caption}>{task.caption}</span>
        <span className={style.subCaption}>{task.subCaption}</span>
        {task.creator && <UserName className={style.creator} {...task.creator} />}
        <div className={style.statuses}>
          <span>{task.groupName}</span>
          <span style={{background: statusBgColor, color: statusTextColor}}>{task.statusName}</span>
        </div>
      </div>
    </Link>
  );
};

export default TaskListActualItem;
