import {createAction} from "typesafe-actions";
import {TaskResponse} from "types/api/taskActual";
import {EmployeeResponse} from "types/api/employee/employeeReadDict";
import {TTheme} from "types/ui/theme";
import {TCurrentUser, TCurrentUserCommon, TUserMenuItem} from "types/api/currentUser";
import {TLeftSidebar, TRightSidebar} from "types/ui";
import {TFileType} from "types/api/fileType";

export const tokenRequested = createAction("TOKEN_REQUESTED")();

export const tokenReceived = createAction("TOKEN_RECEIVED", (token: string) => ({
  token,
}))<{token: string}>();

export const tokenFailed = createAction("TOKEN_FAILED", (message: string) => ({
  message,
}))<{message: string}>();

export const tokenExpired = createAction("TOKEN_EXPIRED")();

export const userInfoRequested = createAction("USER_INFO_REQUESTED")();

export const userInfoReceived = createAction(
  "USER_INFO_RECEIVED",
  (userInfo: TCurrentUser, commonInfo: TCurrentUserCommon, menu: TUserMenuItem[]) => ({userInfo, commonInfo, menu})
)<{
  userInfo: TCurrentUser;
  commonInfo: TCurrentUserCommon;
  menu: TUserMenuItem[];
}>();

export const userInfoFailed = createAction("USER_INFO_FAILED", (message: string) => ({message}))<{message: string}>();

export const taskListRequested = createAction("TASK_LIST_REQUESTED")();
export const taskListReceived = createAction("TASK_LIST_RECEIVED", (payload) => payload)<TaskResponse>();
export const taskListFailed = createAction("TASK_LIST_FAILED")();

export const employeeListRequested = createAction("EMPLOYEE_LIST_REQUESTED", (page: number) => ({page}))<{
  page: number;
}>();
export const employeeListReceived = createAction(
  "EMPLOYEE_LIST_RECEIVED",
  (data: EmployeeResponse, reset?: boolean) => ({
    data,
    reset,
  })
)<{data: EmployeeResponse; reset: boolean | undefined}>();
export const employeeListFailed = createAction("EMPLOYEE_LIST_FAILED")();

export const themeChanged = createAction("THEME_CHANGED", (theme: TTheme) => ({
  theme,
}))<{theme: TTheme}>();

export const gatewayChanged = createAction("GATEWAY_CHANGED", (payload) => payload)<string>();

export const leftSidebarChanged = createAction("LEFT_SIDEBAR_CHANGED", (sidebar: TLeftSidebar) => ({
  sidebar,
}))<{sidebar: TLeftSidebar}>();

export const rightSidebarChanged = createAction("RIGHT_SIDEBAR_CHANGED", (sidebar: TRightSidebar) => ({
  sidebar,
}))<{sidebar: TRightSidebar}>();

export const taskFileTypesRequested = createAction("TASK_FILE_TYPES_REQUESTED")();

export const taskFileTypesFailed = createAction("TASK_FILE_TYPES_FAILED")();

export const taskFileTypesReceived = createAction("TASK_FILE_TYPES_RECEIVED", (payload) => payload)<TFileType[]>();

export const employeeListOpened = createAction("EMPLOYEE_LIST_OPENED")();

export const employeeFilterChanged = createAction("EMPLOYEE_FILTER_CHANGED", (filter: string) => ({filter}))<{
  filter: string;
}>();

export const pageAccessRequested = createAction("PAGE_ACCESS_REQUESTED")();

export const pageAccessFailed = createAction("PAGE_ACCESS_FAILED", (payload) => payload)<string>();

export const pageAccessReceived = createAction("PAGE_ACCESS_RECEIVED", (payload) => payload)<string>();
