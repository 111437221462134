import React from "react";
import {useTheme} from "utils/hooks";
import {TTask} from "types/api/task";
import TaskListActualItem from "components/task/task-list-actual-item";
import styles from "./task-list-actual-style";

export type TTaskListActualProps = {
  items: TTask[];
} & typeof TaskListActualDefaultProps;
const TaskListActualDefaultProps = {
  items: [] as TTask[],
};

const TaskListActual = ({items}: TTaskListActualProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];

  return (
    <div className={style.container}>
      {items.map((item, itemIndex) => (
        <TaskListActualItem key={item.oid} task={item} />
      ))}
    </div>
  );
};
TaskListActual.defaultProps = TaskListActualDefaultProps;

export default TaskListActual;
