import React from "react";
import cn from "classnames";
import {useFormContext, Controller, EventFunction} from "react-hook-form";
import {Form} from "semantic-ui-react";
import {useTheme} from "utils/hooks";
import styles from "./form-field-style";

type WrapperProps = {
  labelFor: string;
  label: string;
  children: React.ReactNode;
} & typeof WrapperDefaultProps;
const WrapperDefaultProps = {
  // default props
};

export const Wrapper = ({labelFor, label, children}: WrapperProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];

  return (
    <Form.Field className={cn(style.field)}>
      <label htmlFor={labelFor}>{label}</label>
      {children}
    </Form.Field>
  );
};
Wrapper.defaultProps = WrapperDefaultProps;

type FormFieldProps = {
  name: string;
  label?: string;
  placeholder?: string;
  inputType?: string;
  rules?: object;
  as?: React.ReactElement;
  simple?: boolean;
  onChange?: EventFunction;
  defaultValue?: string | number;
} & typeof FormFieldPropsDefaultProps;
const FormFieldPropsDefaultProps = {
  label: "",
  placeholder: "",
  inputType: "text",
  simple: false,
};

const FormField = ({
  name,
  as,
  simple,
  label,
  placeholder,
  inputType,
  rules = {},
  defaultValue,
  onChange,
}: FormFieldProps): React.ReactElement => {
  const {errors} = useFormContext();

  const field = (
    <Controller
      as={as || <Form.Input id={name} type={inputType} placeholder={placeholder} error={Boolean(errors[name])} />}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      onChange={onChange}
    />
  );

  if (simple) {
    return field;
  }

  return (
    <Wrapper labelFor={name} label={label}>
      {field}
    </Wrapper>
  );
};
FormField.defaultProps = FormFieldPropsDefaultProps;

export default FormField;
