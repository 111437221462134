import {RootState} from "reducers";
import {map, orderBy} from "lodash-es";
import {TTask, TTaskGroup, TTaskGroupStatus} from "types/api/task";
import {TChild, TGroup, TTaskData} from "types/api/taskActual";
import {createSelector} from "reselect";
import {mapResponse} from "utils/helpers";

export const isTaskListInit = (state: RootState): boolean => state.taskList.isInit;

export const isTaskListLoading = (state: RootState): boolean => state.taskList.isLoading;

export const getTaskListActualItems = (state: RootState): TTask[] =>
  orderBy(map<TTaskData, TTask>(state.taskList.tasks, mapResponse), [(task): number => task.dateCreate], ["desc"]);

export const getTaskListItems = (state: RootState): TTask[] => map<TTaskData, TTask>(state.taskList.tasks, mapResponse);

export const getTaskGroups = (state: RootState): TTaskGroup[] =>
  map<TGroup, TTaskGroup>(state.taskList.groups, mapResponse);

export const getTaskGroupStatuses = createSelector(
  (state: RootState) => state.taskList.groups,
  (state: RootState) => state.taskList.tasks,
  (_, groupName?: string) => groupName,
  (groups, tasks, groupName): TTaskGroupStatus[] => {
    const group = groups.find((item) => item.Name === groupName);
    if (!group) {
      return [];
    }
    const result = map<TChild, TTaskGroupStatus>(group.Childs, mapResponse);
    return result.map((status) => {
      const {StatusColorHEX: statusColorHEX, StatusTextColorHEX: statusTextColorHEX} =
        tasks.find((task) => task.StatusOID === status.statusOID) || ({} as TTaskData);
      return {...status, statusColorHEX, statusTextColorHEX};
    });
  }
);
