import React from "react";
import CN from "classnames";
import {Segment as BaseSegment, StrictSegmentProps} from "semantic-ui-react";
import {useTheme} from "utils/hooks";
import styles from "./segment-style";

type TSegmentExtProps = {
  page?: boolean;
};

export type TSegmentProps = StrictSegmentProps & TSegmentExtProps;

const Segment = ({page, ...rest}: TSegmentProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];

  return <BaseSegment className={CN(style.container, {[style.page]: page})} {...rest} />;
};

export default Segment;
