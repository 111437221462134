import {isObject, isString, mapKeys, mapValues} from "lodash-es";
import {camel} from "case";

export const blobToString = (b: Blob): string => {
  const u = URL.createObjectURL(b);
  const x = new XMLHttpRequest();
  x.open("GET", u, false);
  x.send();
  URL.revokeObjectURL(u);
  return x.responseText;
};

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const mapResponse = <T extends object, K extends object>(data?: T): K => {
  if (!data) {
    return {} as K;
  }
  return mapValues(
    mapKeys(data, (value, key) => camel(key)),
    (value) => (isObject(value) ? mapResponse(value) : value)
  ) as K;
};

export const humanFileSize = (size: number): [number, number] => {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  const formattedSize = Number((size / 1024 ** i).toFixed(2));
  return [formattedSize, i];
};

export const seqExec = async <T extends {}>(list: T[], execFn: (item: T) => Promise<void>): Promise<void> =>
  list.reduce<Promise<void>>(async (previousPromise: Promise<void>, value: T): Promise<void> => {
    await previousPromise;
    return execFn(value);
  }, Promise.resolve());

export const getFormDataFromObject = (data: object): FormData => {
  const formData = new FormData();
  Object.entries(data)
    .filter(([_, value]) => !!value)
    .forEach(([key, value]) => formData.append(key, value.toString()));
  return formData;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isStateless = (Component: any): boolean =>
  !isString(Component) && !(Component.render || (Component.prototype && Component.prototype.render));
