import produce from "immer";
import {Action, createReducer} from "typesafe-actions";
import {TTheme} from "types/ui/theme";
import {leftSidebarChanged, rightSidebarChanged, themeChanged} from "actions";
import {THEME_DEFAULT} from "constants/theme";
import {TLeftSidebar, TRightSidebar} from "types/ui";

interface TUIState {
  readonly theme: TTheme;
  readonly leftSidebar?: TLeftSidebar;
  readonly rightSidebar?: TRightSidebar;
}

const initialState: TUIState = {
  theme: THEME_DEFAULT,
  leftSidebar: "basic",
  rightSidebar: "actualTasks",
};

export default createReducer<TUIState, Action>(initialState)
  .handleAction(themeChanged, (state, {payload}) => {
    const {theme} = payload;
    return produce(state, (draft) => {
      draft.theme = theme;
    });
  })
  .handleAction(leftSidebarChanged, (state, {payload: {sidebar}}) =>
    produce(state, (draft) => {
      draft.leftSidebar = sidebar;
    })
  )
  .handleAction(rightSidebarChanged, (state, {payload: {sidebar}}) =>
    produce(state, (draft) => {
      draft.rightSidebar = sidebar;
    })
  );
