import {Store, createStore, applyMiddleware, compose} from "redux";
import {createBrowserHistory} from "history";
import {routerMiddleware} from "connected-react-router";
import {Action} from "typesafe-actions";
import thunkMiddleware, {ThunkMiddleware, ThunkAction} from "redux-thunk";
import createRootReducer, {RootState} from "reducers/index";
import {initKeyboardActions} from "store/keyboard";
import mapKeyboardEventToAction from "store/keyboard-shortcuts";
import {persistStore, persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel1 from "redux-persist/lib/stateReconciler/autoMergeLevel1";
import {Persistor} from "redux-persist/es/types";

export type ThunkResult<R> = ThunkAction<R, RootState, undefined, Action>;

export const history = createBrowserHistory();

/* eslint-disable @typescript-eslint/no-explicit-any */
const composeEnhancers =
  typeof window === "object" &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  process.env.NODE_ENV === "development"
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;
/* eslint-enable @typescript-eslint/no-explicit-any */

const configureStore = (): [Store<RootState>, Persistor] => {
  const persistConfig = {
    key: "root",
    storage,
    whitelist: ["ui"],
    stateReconciler: autoMergeLevel1,
  };
  const pReducer = persistReducer<RootState>(persistConfig, createRootReducer(history));

  const store = createStore(
    pReducer,
    composeEnhancers(applyMiddleware(thunkMiddleware as ThunkMiddleware<RootState, Action>, routerMiddleware(history)))
  );
  const persistor = persistStore(store);

  initKeyboardActions(store, mapKeyboardEventToAction);

  return [store, persistor];
};

export default configureStore;
