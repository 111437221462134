import {useEffect, useRef} from "react";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {getTheme} from "selectors/app";

export const useTheme = (): ReturnType<typeof getTheme> => {
  return useSelector(getTheme);
};

export const useQuery = (): URLSearchParams => {
  return new URLSearchParams(useLocation().search);
};

export const useDesktop = (): boolean => {
  const query = useQuery();
  const value = Number(query.get("desktop"));
  return value === 1;
};

export const usePrevious = <T,>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

export const useOnce = <T,>(provider: () => T): (() => T) => {
  const ref = useRef<T>();
  return (): T => {
    if (!ref.current) {
      ref.current = provider();
    }
    return ref.current;
  };
};
