import {trim, join, flatMapDeep} from "lodash-es";
import {oc} from "ts-optchain";
import {RootState} from "reducers";
import {TCurrentUser, TCurrentUserCommon} from "types/api/currentUser";

export const isLogged = (state: RootState): boolean => state.auth.token !== "";

export const getToken = (state: RootState): string => state.auth.token;

export const getErrorText = (state: RootState): string | undefined => state.auth.errorText;

export const isLoadingUserInfo = (state: RootState): boolean => state.auth.isLoadingUserInfo;

export const isLoadedUserInfo = (state: RootState): boolean => state.auth.isLoadedUserInfo;

export const getFullName = (state: RootState): string => {
  const user = oc(state).auth.currentUser({} as TCurrentUser);
  const {lastName, firstName} = user;
  return trim(join([lastName, firstName], " "));
};

export const getEmployeeOID = ({auth: {currentUser: {employeeOID} = {} as TCurrentUser}}: RootState): number =>
  employeeOID;

export const getUserID = ({auth: {currentUser: {userID} = {} as TCurrentUser}}: RootState): number => userID;

export const getGatewayId = ({auth: {gatewayId}}: RootState): string | undefined => gatewayId;

export const getAutoSalesId = ({
  auth: {commonInfo: {autoSalesUserID} = {} as TCurrentUserCommon},
}: RootState): number | undefined => autoSalesUserID;

export const getAllUserMenuItems = ({auth: {menu}}: RootState): string[] =>
  flatMapDeep(menu || [], (item) => [item].concat(item.items || []))
    .map((item) => item.link)
    .filter((link) => !!link);
