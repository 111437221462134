import {Store} from "redux";
import {Action} from "typesafe-actions";
import {RootState} from "reducers";

// eslint-disable-next-line import/prefer-default-export
export const initKeyboardActions = (
  store: Store,
  getAction: (state: RootState, keyboardEvent: KeyboardEvent) => Action | null
): void => {
  const handleEvent = (keyboardEvent: KeyboardEvent): void => {
    const action = getAction(store.getState(), keyboardEvent);
    if (action) {
      store.dispatch(action);
    }
  };

  window.addEventListener("keyup", handleEvent);
};
