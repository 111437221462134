import {PARTNER_V1_PATH, TEST_API_URL} from "constants/common";

const isDesktopMode = (): boolean => {
  const urlParams = new URLSearchParams(window.location.search);
  const desktop = urlParams.get("desktop");
  return desktop === "1";
};

const getDesktopSlug = (): string => {
  return isDesktopMode() ? "?desktop=1" : "";
};

// strip query string for correct path math in Route component
export const toPath = (url = ""): string => {
  return url.split("?")[0];
};

export const homeUrl = (): string => {
  return `/${getDesktopSlug()}`;
};

export const loginUrl = (): string => {
  return `/login${getDesktopSlug()}`;
};

export const logoutUrl = (): string => {
  return `/logout${getDesktopSlug()}`;
};

// Employee

export const employeeListUrl = (): string => {
  return `/employee/list${getDesktopSlug()}`;
};

export const employeeViewUrl = (oid?: number, tab?: string): string => {
  return `/employee/${oid || ":oid"}/view/tab/${oid ? tab || "default" : ":tab"}${getDesktopSlug()}`;
};

// Task

export const taskListUrl = (): string => {
  return `/task/list${getDesktopSlug()}`;
};

export const taskCurrentListUrl = (): string => {
  return `/task/current-list${getDesktopSlug()}`;
};

export const taskViewUrl = (oid?: number, tab?: string): string => {
  return `/task/${oid || ":oid"}/view/tab/${oid ? tab || "default" : ":tab"}${getDesktopSlug()}`;
};

// Messages
export const messagesUrl = (oid?: number, pageNumber?: number, messageOid?: number, baseUrl?: string): string => {
  return `/${baseUrl === TEST_API_URL ? "client-history" : "message"}${oid ? `/${oid}` : ""}${
    pageNumber ? `/page/${pageNumber}${messageOid ? `/message/${messageOid}` : ""}` : ""
  }${getDesktopSlug()}`;
};

// Chatbot
export const chatbotUrl = (oid?: string, pageNumber?: number, messageOid?: string): string => {
  return `/chatbot${oid ? `/${oid}` : ""}${
    pageNumber ? `/page/${pageNumber}${messageOid ? `/message/${messageOid}` : ""}` : ""
  }${getDesktopSlug()}`;
};

// Orders
export const farmOrderActiveListUrl = (): string => {
  return `${PARTNER_V1_PATH}/farm/grid/act${getDesktopSlug()}`;
};
export const farmOrderArchiveListUrl = (): string => {
  return `${PARTNER_V1_PATH}/farm/grid/arc${getDesktopSlug()}`;
};
export const farmOrderCreateUrl = (): string => {
  return `${PARTNER_V1_PATH}/farm/form/create${getDesktopSlug()}`;
};
// export const farmOrderViewUrl = (oid?: number, tab?: string): string => {
//   return `/farm/order/${oid || ":oid"}/view/tab/${oid ? tab || "default" : ":tab"}${getDesktopSlug()}`;
// };
// export const farmOrderEditUrl = (oid?: number, tab?: string): string => {
//   return `/farm/order/${oid || ":oid"}/edit/tab/${oid ? tab || "default" : ":tab"}${getDesktopSlug()}`;
// };

// Store
export const farmStoreListUrl = (): string => {
  return `${PARTNER_V1_PATH}/farm/storage/list${getDesktopSlug()}`;
};

// Movement
export const farmMovementUrl = (): string => {
  return `${PARTNER_V1_PATH}/farm/move${getDesktopSlug()}`;
};

// Clients
export const clientsUrl = (): string => "/clients";
