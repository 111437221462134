import React from "react";
import cn from "classnames";
import {TabPane as BaseTabPane, StrictTabPaneProps} from "semantic-ui-react";
import {useTheme} from "utils/hooks";
import styles from "./tab-pane-style";

export type TTabPaneProps = StrictTabPaneProps;

const TabPane = (props: TTabPaneProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];
  const {className, ...rest} = props;

  return <BaseTabPane className={cn(style.container, className)} {...rest} />;
};

export default TabPane;
