import React from "react";
import {useParams} from "react-router-dom";
// import Main from "layouts/main";
import {InlineLoader} from "components/ui/element/loader";

const TaskCardExample = React.lazy(() => import("components/task/task-card-example"));

const TaskExample = (): React.ReactElement => {
  const {id} = useParams();

  return (
    // <Main>
    <React.Suspense fallback={<InlineLoader />}>
      <TaskCardExample id={Number(id)} />
    </React.Suspense>
    // </Main>
  );
};

export default TaskExample;
