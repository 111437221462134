import React from "react";
import {InlineLoader} from "components/ui/element/loader";

const ClientsList = React.lazy(() => import("components/client/clients-list"));

const ClientsRoute = (): React.ReactElement => {
  return (
    <React.Suspense fallback={<InlineLoader />}>
      <ClientsList />
    </React.Suspense>
  );
};

export default ClientsRoute;
