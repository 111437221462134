import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {useLastLocation} from "react-router-last-location";
import {taskListUrl} from "utils/url-builder";
import {InlineLoader} from "components/ui/element/loader";

const TaskView = React.lazy(() => import("components/task/task-view/task-view-container"));

const TaskViewPage = (): React.ReactElement => {
  const [fromUrl, setFromUrl] = useState<string>();
  const {oid: oidString, tab = ""} = useParams();
  const oid = Number(oidString);
  const lastLocation = useLastLocation();

  useEffect(() => {
    if (!fromUrl && lastLocation) {
      setFromUrl(lastLocation.pathname + lastLocation.search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Suspense fallback={<InlineLoader />}>
      <TaskView oid={oid} tab={tab} fromUrl={fromUrl || taskListUrl()} />
    </React.Suspense>
  );
};

export default TaskViewPage;
