import React from "react";
import cn from "classnames";
import {useTheme} from "utils/hooks";
import styles from "./field-label-style";

export type TFieldLabelLayoutType = "vertical" | "horizontal";
export const LAYOUT_MODE_VERTICAL: TFieldLabelLayoutType = "vertical";
export const LAYOUT_MODE_HORIZONTAL: TFieldLabelLayoutType = "horizontal";

type TFieldLabelProps = {
  labelFor?: string;
  label: string;
  children: React.ReactNode;
  layoutMode?: TFieldLabelLayoutType;
  display: boolean;
} & typeof FieldLabelDefaultProps;
const FieldLabelDefaultProps = {
  labelFor: null,
  display: true,
};

const FieldLabel = ({label, labelFor, children, layoutMode, display}: TFieldLabelProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];

  if (!display) {
    return <></>;
  }

  return (
    <div className={cn(style.container, layoutMode === LAYOUT_MODE_VERTICAL ? style.verticalMode : null)}>
      <span>{label}</span>
      {children}
    </div>
  );
};
FieldLabel.defaultProps = FieldLabelDefaultProps;

export default FieldLabel;
