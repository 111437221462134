import produce from "immer";
import {Action, createReducer} from "typesafe-actions";
import {TFileType} from "types/api/fileType";
import {taskFileTypesFailed, taskFileTypesReceived, taskFileTypesRequested} from "actions";

interface TTaskFileTypeState {
  readonly data: TFileType[];
  readonly loaded: boolean;
  readonly loading: boolean;
}

const initialState: TTaskFileTypeState = {
  data: [] as TFileType[],
  loaded: false,
  loading: false,
};

export default createReducer<TTaskFileTypeState, Action>(initialState)
  .handleAction(taskFileTypesRequested, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    })
  )
  .handleAction(taskFileTypesFailed, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.loaded = true;
    })
  )
  .handleAction(taskFileTypesReceived, (state, {payload}) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.loaded = true;
      draft.data = payload;
    })
  );
