import {BaseApi} from "api/common";
import {TTaskFile, TTaskFiles, TTaskFileType} from "types/task";
import {getFormDataFromObject} from "utils/helpers";
import {TSimpleChatMessage} from "components/chat/chat-message/chat-message";
import _ from "lodash-es";
import {TTaskStatusHistoryResponse} from "types/api/taskStatusHistory";
import {TTaskStatusHistoryItem} from "components/task/task-view/task-status-history/task-status-history";
import {TaskInstanceResponse, TUpdateStatusResponse} from "types/api/taskInstance";
import qs from "qs";
import {TaskResponse} from "types/api/taskActual";

type TTaskFilesResponse = {
  type: {
    OID: number;
    Code: string;
    Name: string;
  }[];
  file: {
    CreateMonthYearStr: string;
    MonthNum: number;
    YearNum: number;
    list: {
      CreateDate: string;
      Owner: {
        OID: number;
        Name: string;
      };
      OID: number;
      ID: number;
      FileName: string;
      FileExt: string;
      FileSize: number;
      IsAvailable: true;
      IsOwner: false;
      Notes: string;
      IsReadAccess: true;
      type: {
        OID: number;
        Code: string;
        Name: string;
        IsNotForObjectUnits: null;
      };
    }[];
  }[];
};

type TTaskMessages = {
  ID: number;
  UserID: number;
  EmployeeID: number;
  Note: string;
  UserID_ShortName: string;
  Date: string;
  EmployeeOID: number;
  IsCanDelete: boolean;
}[];

type TTaskMessagesResponse = TTaskMessages[];

class TaskApi extends BaseApi {
  async getActualTasks(): Promise<TaskResponse> {
    return this.fetchApi({url: "/MASP/MSV2/Task/ReadActual"});
  }

  async getTask(oid: number): Promise<TaskInstanceResponse> {
    return this.fetchApi({
      url: "/MASP/MSV2/Task/ReadInstance",
      params: {TaskOID: oid},
    });
  }

  async updateTaskStatus(transitionOid: number): Promise<TUpdateStatusResponse> {
    return this.fetchApi({
      url: "/MASP/MSV2/Task/UpdateStatus",
      params: {
        OID: transitionOid,
      },
    });
  }

  async getStatusHistory(oid: number): Promise<TTaskStatusHistoryItem[]> {
    return this.fetchApi<TTaskStatusHistoryResponse, TTaskStatusHistoryItem[]>({
      url: "/MASP/MSV2/Task/ReadStatusHistory",
      params: {OID: oid},
      mapFn: this.mapTaskStatusHistoryResponse,
    });
  }

  async sendMessage(oid: number, text: string): Promise<void> {
    await this.fetchApi({
      url: "/MASP/MSV1/RSTQueryNote/Create",
      params: {
        OID: oid,
        Note: text,
      },
    });
  }

  async getMessages(oid: number): Promise<TSimpleChatMessage[]> {
    return this.fetchApi<TTaskMessagesResponse, TSimpleChatMessage[]>({
      url: "/MASP/MSV1/RSTQueryNote/ReadByQuery",
      params: {OID: oid},
      mapFn: this.mapTaskMessagesResponse,
    });
  }

  async getFilePreview(oid: number): Promise<Blob> {
    return this.fetchBlob({
      url: `/File/DownloadImagePreviewByFileOID${qs.stringify({fileOID: oid}, {addQueryPrefix: true})}`,
      reqParams: {method: "GET"},
    });
  }

  async uploadFile(oid: number, employeeOID: number, file: File, fileType?: number, notes?: string): Promise<void> {
    const formData = getFormDataFromObject({
      OID: oid,
      Notes: notes,
      FileTypeOID: fileType,
      EmployeeOID: employeeOID,
    });
    formData.append("", file);
    await this.fetchApi<void, void>({url: "/File/UploadFiles", params: formData, reqParams: {method: "POST"}});
  }

  async downloadFile(fileOID: number): Promise<Blob> {
    return this.fetchBlob({url: `/File/DownloadFileByFileOID?fileOID=${fileOID}`, reqParams: {method: "GET"}});
  }

  async deleteFile(fileOID: number): Promise<void> {
    return this.fetchApi({
      url: "/MASP/MSV1/ObjectFiles/Delete",
      params: {
        FileWebID: fileOID,
        IsDeleteAllTrack: true,
        NeedDeleteContentFromLocalServer: true,
      },
    });
  }

  async getFiles(oid: number): Promise<TTaskFiles> {
    return this.fetchApi({
      url: "/MASP/MSV2/File/ReadAttachment",
      params: {OID: oid},
      mapFn: this.mapFilesResponse,
    });
  }

  mapFilesResponseTypes = (data: TTaskFilesResponse): TTaskFileType[] =>
    data.type.map(({OID: oid, Name: name}) => ({oid, name}));

  mapFilesResponseItems = (data: TTaskFilesResponse): TTaskFile[] =>
    data.file
      .map(({list}) =>
        list.map(
          ({OID: oid, FileName: fileName, IsOwner: isOwner, Notes: notes, type: {OID: typeId, Name: typeName}}) => ({
            oid,
            fileName,
            isOwner,
            typeId,
            typeName,
            notes,
          })
        )
      )
      .flat();

  mapFilesResponse = (value: TTaskFilesResponse): TTaskFiles => {
    return {
      types: this.mapFilesResponseTypes(value),
      files: this.mapFilesResponseItems(value),
    };
  };

  mapTaskMessagesResponse = (value: TTaskMessagesResponse): TSimpleChatMessage[] =>
    _.isArray(value) && _.isArray(value[0]) ? this.mapTaskMessagesItems(value[0]) : ([] as TSimpleChatMessage[]);

  mapTaskMessagesItems = (data: TTaskMessages): TSimpleChatMessage[] =>
    data.map(
      ({
        ID: id,
        UserID: userId,
        EmployeeID: employeeId,
        EmployeeOID: employeeOID,
        Note: text,
        UserID_ShortName: userName,
        Date: date,
        IsCanDelete: isCanDelete,
      }) => ({
        id,
        timestamp: new Date(date).getTime(),
        userId,
        employeeId,
        employeeOID,
        userName,
        text,
        isCanDelete,
      })
    );

  mapTaskStatusHistoryResponse = (data: TTaskStatusHistoryResponse): TTaskStatusHistoryItem[] =>
    data.map(
      ({
        Date: dateString,
        UserID: {MiddleName: user},
        Transition: {Name: transition},
        StatusFrom: {Name: statusOld},
        StatusTo: {Name: statusNew},
        MinutesStay: durationMinutes,
      }) => ({
        timestamp: new Date(dateString).getTime(),
        user,
        transition,
        statusOld,
        statusNew,
        durationMinutes,
      })
    );
}

const taskApi = new TaskApi();

export default taskApi;
