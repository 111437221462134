import React from "react";
import cn from "classnames";
import {PARTNER_V1_PATH} from "constants/common";
import {useMessages} from "utils/messages";
import {Header} from "components/ui/element/header";
import {Icon} from "components/ui/element/icon";
import {Link, useLocation} from "react-router-dom";
import {useTheme} from "utils/hooks";
import {first, trim} from "lodash-es";
import styles from "./button-menu-style";
import {TMenuItem} from "../menu";

type TButtonMenuProps = {
  menu: TMenuItem[];
} & typeof ButtonMenuDefaultProps;
const ButtonMenuDefaultProps = {
  menu: [] as TMenuItem[],
};

const getRootUrlPath = (url?: string): string | undefined => first((trim(url, "/") || "").split("/"));

const isCurentSection = (sectionPath: string, locationPath: string): boolean => {
  if (getRootUrlPath(sectionPath) === getRootUrlPath(PARTNER_V1_PATH)) {
    return sectionPath === locationPath;
  }
  return getRootUrlPath(sectionPath) === getRootUrlPath(locationPath);
};

const ButtonMenu = ({menu}: TButtonMenuProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];
  const location = useLocation();
  const messages = useMessages();

  return (
    <div className={style.container}>
      {menu.map((item) => (
        <Link to={item.url} key={item.url}>
          <div
            className={cn(style.menuItem, {
              [style.active]: isCurentSection(item.url, location.pathname),
            })}
          >
            <Header as="h3">
              <Icon name={item.icon} />
              <Header.Content>
                <div>{messages[item.title]}</div>
                {item.subtitle && <Header.Subheader>{messages[item.subtitle]}</Header.Subheader>}
              </Header.Content>
            </Header>
          </div>
        </Link>
      ))}
    </div>
  );
};
ButtonMenu.defaultProps = ButtonMenuDefaultProps;

export default ButtonMenu;
