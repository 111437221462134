import React from "react";
import CN from "classnames";
import {Icon, SemanticICONS} from "semantic-ui-react";
import {useTheme} from "utils/hooks";
import {isString} from "lodash-es";
import {IconSizeProp} from "semantic-ui-react/dist/commonjs/elements/Icon/Icon";
import styles from "./icon-button-style";

type TIconButtonProps = {
  icon: SemanticICONS | React.ReactElement;
  size?: IconSizeProp;
  className?: string;
  title?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
  typeof IconButtonDefaultProps;
const IconButtonDefaultProps = {};

const IconButton = ({className, icon, size, title, ...rest}: TIconButtonProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];

  const iconElement = isString(icon) ? <Icon name={icon} size={size} /> : icon;

  return (
    <button type="button" className={CN(style.container, className)} title={title} {...rest}>
      {iconElement}
    </button>
  );
};
IconButton.defaultProps = IconButtonDefaultProps;

export default IconButton;
