import React, {MouseEvent} from "react";
import CN from "classnames";
import {useTheme} from "utils/hooks";
import {Input} from "semantic-ui-react";
import {useMessages} from "utils/messages";
import {InputOnChangeData} from "semantic-ui-react/dist/commonjs/elements/Input/Input";
import {noop} from "lodash-es";
import IconButton from "components/ui/control/icon-button";
import styles from "./search-input-style";

type TSearchInputProps = {
  value: string;
  className?: string;
  onChange: (value: string) => void;
} & typeof SearchInputDefaultProps;
const SearchInputDefaultProps = {
  onChange: noop as (value: string) => void,
};

const SearchInput = ({value, className, onChange}: TSearchInputProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];
  const msg = useMessages();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, {value: val}: InputOnChangeData): void => {
    onChange(val);
  };

  const handleClearClick = (event: MouseEvent<HTMLButtonElement>): boolean => {
    event.preventDefault();
    onChange("");
    return false;
  };

  return (
    <>
      <Input
        icon="search"
        iconPosition="left"
        className={CN(style.container, className)}
        placeholder={msg.searchPlaceholder}
        onChange={handleChange}
        value={value}
      />
      {value && <IconButton className={style.clear} icon="delete" onClick={handleClearClick} />}
    </>
  );
};
SearchInput.defaultProps = SearchInputDefaultProps;

export default SearchInput;
