import moment, {MomentInput} from "moment";
import messages from "assets/messages.json";

export const defaultDateFormat = "DD.MM.YYYY";

export const formatDateTime = (value: MomentInput, utc = false, format?: string): string => {
  if (!value) {
    return "";
  }
  const dateTime = utc ? moment.utc(value) : moment(value);
  return format ? dateTime.format(format) : dateTime.calendar();
};

export const formatDate = (value: MomentInput, utc = false): string => {
  if (!value) {
    return "";
  }
  const dateTime = utc ? moment.utc(value) : moment(value).startOf("day");
  return dateTime.calendar(undefined, {
    lastDay: `[${messages.calendarYesterday}]`,
    sameDay: `[${messages.calendarToday}]`,
    nextDay: `[${messages.calendarTomorrow}]`,
    afterNextDay: `[${messages.calendarAfterTomorrow}]`,
    nextWeek: "dddd",
    preLastDay: `[${messages.calendarPreYesterday}]`,
    thisYear: "DD MMMM",
    sameElse: "LL",
  });
};
