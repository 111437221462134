import {ThunkResult} from "store/configure-store";
import {
  taskListRequested,
  taskListReceived,
  taskFileTypesRequested,
  taskFileTypesReceived,
  taskFileTypesFailed,
  employeeListRequested,
  employeeListReceived,
  tokenRequested,
  tokenReceived,
  tokenFailed,
  userInfoRequested,
  userInfoReceived,
  userInfoFailed,
  pageAccessRequested,
  pageAccessReceived,
  pageAccessFailed,
} from "actions";
import {getFilter, isEmployeeLoading, isEmployeeLoaded, getEmployeePage} from "selectors/employee";

import {EmployeeResponse} from "types/api/employee/employeeReadDict";
import dictApi from "api/dict";
import employeeApi from "api/employee";
import taskApi from "api/task";
import userApi from "api/user";
import {TCurrentUserToken} from "types/api/currentUser";

export const fetchToken = (login: string, password: string): ThunkResult<Promise<void>> => {
  return async (dispatch): Promise<void> => {
    dispatch(tokenRequested());
    try {
      const data = await userApi.getToken(login, password);
      const {accessToken} = data as TCurrentUserToken;
      dispatch(tokenReceived(accessToken));
    } catch (error) {
      dispatch(tokenFailed(error.message));
    }
  };
};

export const fetchCurrectUserInfo = (): ThunkResult<Promise<void>> => {
  return async (dispatch): Promise<void> => {
    dispatch(userInfoRequested());
    try {
      const data = await userApi.getCurrentUser();
      const commonData = await userApi.getCurrentUserCommon();
      const menu = await userApi.getCurrentUserMenu();
      dispatch(userInfoReceived(data, commonData, menu));
    } catch (error) {
      dispatch(userInfoFailed(error.message));
    }
  };
};

export const fetchTaskList = (): ThunkResult<Promise<void>> => {
  return async (dispatch): Promise<void> => {
    dispatch(taskListRequested());
    try {
      const data = await taskApi.getActualTasks();
      dispatch(taskListReceived(data));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("fetchTaskList", error);
      dispatch(taskListReceived([]));
    }
  };
};

export const fetchEmployeeList = (page = 1, reset?: boolean): ThunkResult<Promise<void>> => {
  return async (dispatch, getState): Promise<void> => {
    const filter = getFilter(getState());
    dispatch(employeeListRequested(page));
    try {
      const data = await employeeApi.getList(page, filter);
      if (data) {
        dispatch(employeeListReceived(data, reset));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("fetchEmployeeList", error);
      dispatch(employeeListReceived({} as EmployeeResponse, reset));
    }
  };
};

export const employeeItemRendered = (index: number): ThunkResult<Promise<void>> => {
  return async (dispatch, getState): Promise<void> => {
    const loaded = isEmployeeLoaded(getState(), index);
    const loading = isEmployeeLoading(getState(), index);
    if (loaded || loading) {
      return;
    }
    const page = getEmployeePage(getState(), index);
    await dispatch(fetchEmployeeList(page));
  };
};

export const fetchTaskFileTypesList = (): ThunkResult<Promise<void>> => {
  return async (dispatch): Promise<void> => {
    dispatch(taskFileTypesRequested());
    try {
      const data = await dictApi.getFileTypes();
      dispatch(taskFileTypesReceived(data));
    } catch (error) {
      dispatch(taskFileTypesFailed());
    }
  };
};

export const fetchPageAccess = (path: string): ThunkResult<Promise<void>> => {
  return async (dispatch): Promise<void> => {
    dispatch(pageAccessRequested());
    try {
      await userApi.hasAccess(path);
      dispatch(pageAccessReceived(path));
    } catch (error) {
      dispatch(pageAccessFailed(path));
    }
  };
};
