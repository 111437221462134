import React from "react";
import {useMessages} from "utils/messages";
import {useDialog} from "components/ui/modal";
import BaseDialog from "components/ui/modal/base-dialog";
import {TDialogProps} from "components/ui/modal/base-dialog/base-dialog";

type TErrorDialogProps = {
  error?: string;
} & TDialogProps &
  typeof ErrorDialogDefaultProps;
const ErrorDialogDefaultProps = {};

const ErrorDialog = ({error, className}: TErrorDialogProps): React.ReactElement => {
  const msg = useMessages();
  const {errorModalHeader, processError, okButton} = msg;
  const {hideDialog} = useDialog();

  return (
    <BaseDialog
      header={{icon: "warning circle", content: errorModalHeader}}
      content={
        <div style={{padding: 15}}>
          <h3>{error || processError}</h3>
        </div>
      }
      actions={[{key: "ok", content: okButton, positive: true, onClick: hideDialog}]}
    />
  );
};
ErrorDialog.defaultProps = ErrorDialogDefaultProps;

export default ErrorDialog;
