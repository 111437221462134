import React, {useEffect} from "react";
import CN from "classnames";
import TopMenu from "components/top-menu";
import Sidebar from "components/sidebar";
import {useTheme, useDesktop} from "utils/hooks";
import TaskCurrentListContainer from "components/task/task-list-actual/task-current-list-container";
import {useLocation} from "react-use";
import styles from "./main.style";
import {useIsMobile, useLeftSidebarType, useRightSidebarType} from "./context";

const useLeftSidebar = (): React.ReactElement | null => {
  const theme = useTheme();
  const style = styles[theme];
  const {isMobile} = useIsMobile();
  const leftSidebarType = useLeftSidebarType();
  switch (leftSidebarType) {
    case "basic":
      return (
        <div className={CN(style.menu, {[style.mobileMenu]: isMobile})}>
          <Sidebar />
        </div>
      );
    default:
      return null;
  }
};

const useRightSidebar = (): React.ReactElement | null => {
  const theme = useTheme();
  const style = styles[theme];
  const {isMobile} = useIsMobile();
  const rightSidebarType = useRightSidebarType();
  switch (rightSidebarType) {
    case "actualTasks":
      return (
        <div className={CN(style.menu, {[style.mobileMenu]: isMobile})}>
          <TaskCurrentListContainer filterClassName={style.filterSidebar} />
        </div>
      );
    default:
      return null;
  }
};

const Main: React.FC = ({children}) => {
  const isDesktop = useDesktop();
  const theme = useTheme();
  const style = styles[theme];
  const leftSidebar = useLeftSidebar();
  const rightSidebar = useRightSidebar();
  const {setSidebarMobileState} = useIsMobile();
  const {href} = useLocation();
  useEffect(() => {
    setSidebarMobileState({});
  }, [href, setSidebarMobileState]);

  if (isDesktop) {
    return <>{children}</>;
  }
  return (
    <div className={style.container}>
      <TopMenu />
      <div className={style.body}>
        {leftSidebar}
        <div className={style.content}>{children}</div>
        {rightSidebar}
      </div>
    </div>
  );
};

export default Main;
