/* eslint-disable import/prefer-default-export */
import React from "react";
import cn from "classnames";
import {LOCALE} from "constants/common";
import DayPickerBase, {DayPickerProps as DayPickerPropsBase} from "react-day-picker";
import {useTheme} from "utils/hooks";
import styles from "./day-picker-style";
import "react-day-picker/lib/style.css";

type TDayPickerProps = DayPickerPropsBase;

type TLocale = "ru";

const WEEKDAYS_LONG = {
  ru: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
};
const WEEKDAYS_SHORT = {
  ru: ["ВС", "ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ"],
};
const MONTHS = {
  ru: [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ],
};

const FIRST_DAY = {
  ru: 1,
};

function formatDay(d: Date, locale: TLocale = LOCALE): string {
  return `${WEEKDAYS_LONG[locale][d.getDay()]}, ${d.getDate()} ${MONTHS[locale][d.getMonth()]} ${d.getFullYear()}`;
}

function formatMonthTitle(d: Date, locale: TLocale = LOCALE): string {
  return `${MONTHS[locale][d.getMonth()]} ${d.getFullYear()}`;
}

function formatWeekdayShort(i: number, locale: TLocale): string {
  return WEEKDAYS_SHORT[locale][i];
}

function formatWeekdayLong(i: number, locale: TLocale): string {
  return WEEKDAYS_SHORT[locale][i];
}

function getFirstDayOfWeek(locale: TLocale): number {
  return FIRST_DAY[locale];
}

const localeUtils = {
  formatDay,
  formatMonthTitle,
  formatWeekdayShort,
  formatWeekdayLong,
  getFirstDayOfWeek,
};

export const DayPicker = ({className, ...rest}: TDayPickerProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];

  return <DayPickerBase locale={LOCALE} localeUtils={localeUtils} className={cn(style.control, className)} {...rest} />;
};
