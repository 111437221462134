import {BaseApi} from "api/common";
import {TEmployeeInstanceResponse} from "types/api/employee/employeeInstance";
import qs from "qs";
import {EmployeeResponse} from "types/api/employee/employeeReadDict";
import axios, {CancelTokenSource} from "axios";

class EmployeeApi extends BaseApi {
  private cancelGetList?: CancelTokenSource;

  async getList(page: number, filter?: string): Promise<EmployeeResponse | undefined> {
    try {
      if (this.cancelGetList) {
        this.cancelGetList.cancel();
      }
      this.cancelGetList = axios.CancelToken.source();
      return await this.fetchApi<EmployeeResponse>({
        url: "/MASP/MSV2/Employee/ReadDict",
        params: {
          FIO: filter || "",
          Page: page,
        },
        reqParams: {
          cancelToken: this.cancelGetList.token,
        },
      });
    } catch (error) {
      if (!axios.isCancel(error)) {
        throw error;
      }
      return undefined;
    }
  }

  async getAvatar(oid: number): Promise<Blob> {
    return this.fetchBlob({
      url: `/File/GetEmployeePhoto${qs.stringify({employeeOID: oid}, {addQueryPrefix: true})}`,
      reqParams: {method: "GET"},
    });
  }

  async get(oid: number): Promise<TEmployeeInstanceResponse> {
    return this.fetchApi<TEmployeeInstanceResponse>({
      url: "/MASP/MSV2/Employee/ReadInstance",
      params: {OID: oid},
    });
  }
}

const employeeApi = new EmployeeApi();

export default employeeApi;
