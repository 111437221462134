import React, {useContext} from "react";
import messages from "assets/messages.json";

const messagesContext = React.createContext(messages);

type TMessagesProviderProps = {
  children: React.ReactNode;
};
export const MessagesProvider = ({children}: TMessagesProviderProps): React.ReactElement => (
  <messagesContext.Provider value={messages}>{children}</messagesContext.Provider>
);

export const useMessages = (): {[key: string]: string} => {
  return useContext(messagesContext);
};
