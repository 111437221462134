/* eslint-disable no-nested-ternary */
import React, {useEffect} from "react";
import * as moment from "moment";
import "moment/locale/ru";
import {useDispatch, useSelector} from "react-redux";
import qs from "qs";
import {useHistory, useLocation} from "react-router-dom";
import {getToken, isLoadedUserInfo, isLoadingUserInfo} from "selectors/auth";
import {tokenReceived} from "actions";
import {fetchCurrectUserInfo} from "actions/thunk";
import messages from "assets/messages.json";
import {LOCALE} from "constants/common";

const setupDateFormat = (): void => {
  moment.locale(LOCALE);
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  moment.calendarFormat = (myMoment: moment.Moment, now: moment.Moment): string => {
    const diff = myMoment.diff(now, "days", true);
    return diff < -2
      ? myMoment.year() === now.year()
        ? "thisYear"
        : "sameElse"
      : diff < -1
      ? "preLastDay"
      : diff < 0
      ? "lastDay"
      : diff < 1
      ? "sameDay"
      : diff < 2
      ? "nextDay"
      : diff < 3
      ? "afterNextDay"
      : myMoment.year() === now.year()
      ? "thisYear"
      : "sameElse";
  };
  moment.updateLocale(LOCALE, {
    calendar: {
      preLastDay: `[${messages.calendarPreYesterday}], LT`,
      lastDay: `[${messages.calendarYesterday}], LT`,
      sameDay: "LT",
      nextDay: `[${messages.calendarTomorrow}, в] LT`,
      afterNextDay: `[${messages.calendarAfterTomorrow}, в] LT`,
      thisYear: "DD MMMM, LT",
      sameElse: "DD.MM.YYYY, LT",
    },
  });
};

const AppDataLoader: React.FC = ({children}): React.ReactElement => {
  const isLoading = useSelector(isLoadingUserInfo);
  const isLoaded = useSelector(isLoadedUserInfo);
  const token = useSelector(getToken);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  setupDateFormat();

  useEffect(() => {
    const {token: queryToken, ...rest} = qs.parse(location.search, {ignoreQueryPrefix: true});
    if (queryToken) {
      dispatch(tokenReceived(queryToken));
      history.push(location.pathname + qs.stringify(rest, {addQueryPrefix: true}));
      return;
    }
    if (!token || isLoaded || isLoading) {
      return;
    }
    dispatch(fetchCurrectUserInfo());
  });
  if (token && (isLoading || !isLoaded)) {
    return <div />;
  }
  return <>{children}</>;
};

export default AppDataLoader;
