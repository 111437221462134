import React, {MouseEventHandler, useState, useEffect} from "react";
import moment from "moment";
import {useSelector} from "react-redux";
import Tab from "components/ui/element/tab";
import {TCurrentUserInstance, TScheduleItem} from "types/api/currentUser";
import {Modifiers} from "react-day-picker";
import userApi from "api/user";
import {getEmployeeOID} from "selectors/auth";
import ViewCard from "components/common/view-card";
import FieldLabel from "components/ui/element/field-label";
import FieldText from "components/ui/element/field-text";
import FieldInteger from "components/ui/element/field-integer";
import FieldPhone from "components/ui/element/field-phone";
import {Form, Message, Popup} from "semantic-ui-react";
import {Button} from "components/ui/control/button";
import {DayPicker} from "components/ui/control/day-picker";
import FormField from "components/ui/form-field";
import {FormContext, useForm} from "react-hook-form";
import {Avatar} from "components/common/avatar";
import {formatDate} from "utils/date";
import {useMessages} from "utils/messages";
import {useTheme} from "utils/hooks";
import {TAsyncResult} from "api/common";
import styles from "./user-view-card-style";

export type TUserViewCardProps = {
  hideButtons?: boolean;
  onClickLinkOutside?: MouseEventHandler<HTMLAnchorElement>;
} & TAsyncResult<TCurrentUserInstance> &
  typeof UserViewCardDefaultProps;
const UserViewCardDefaultProps = {
  data: {} as TCurrentUserInstance,
};

type TProfileContactFields = {
  phone: string;
  email: string;
  passwordRecoveryPhone: string;
  passwordRecoveryEmail: string;
};

const UserViewCard = (props: TUserViewCardProps): React.ReactElement => {
  const theme = useTheme();
  const oid = useSelector(getEmployeeOID);
  const msg = useMessages();
  const methods = useForm<TProfileContactFields>();
  const style = styles[theme];
  const [errorText, setErrorText] = useState("");
  const [successText, setSuccessText] = useState("");
  const [displayMessage, setDisplayMessage] = useState(true);
  const [schedule, setSchedule] = useState([] as TScheduleItem[]);

  const {data, hideButtons /* , onClickLinkOutside = noop */} = props;

  useEffect(() => {
    handleMonthChange(new Date());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormContactsSubmit = async (formData: TProfileContactFields): Promise<void> => {
    setDisplayMessage(true);
    try {
      await userApi.saveProfileContacts(
        formData.phone,
        formData.email,
        formData.passwordRecoveryPhone,
        formData.passwordRecoveryEmail
      );
    } catch (error) {
      setErrorText(error.message);
      return;
    }
    setSuccessText(msg.userProfileContactsSaved);
  };

  const handleDismiss = (): void => {
    setDisplayMessage(false);
  };

  const handleMonthChange = async (month: Date): Promise<void> => {
    const date1 = moment(month).startOf("month");
    const date2 = moment(month)
      .startOf("month")
      .add(1, "M")
      .subtract(1, "s");
    const scheduleData = await userApi.getSchedule(oid, date1, date2);
    setSchedule(scheduleData);
  };

  const modifierKeyFormat = "YYYYMMDD";

  const prepareModifiers = (scheduleData: TScheduleItem[]): Partial<Modifiers> => {
    if (scheduleData.length === 0) {
      return {};
    }
    const modifiers = {} as Partial<Modifiers>;
    scheduleData.forEach((item) => {
      modifiers[item.timeStart.format(modifierKeyFormat)] = [item.timeStart.toDate()];
    });
    return modifiers;
  };

  const prepareModifierStyles = (scheduleData: TScheduleItem[]): object => {
    if (scheduleData.length === 0) {
      return {};
    }
    const modifiers = {} as {[key: string]: object};
    scheduleData.forEach((item) => {
      modifiers[item.timeStart.format(modifierKeyFormat)] = {
        backgroundColor: item.color,
        borderRadius: "5px",
      };
    });
    return modifiers;
  };

  const prepareTitles = (scheduleData: TScheduleItem[]): {[key: string]: string} => {
    if (scheduleData.length === 0) {
      return {} as {[key: string]: string};
    }
    const titles = {} as {[key: string]: string};
    scheduleData.forEach((item) => {
      let title = item.workHours;
      if (item.additionalInfo) {
        title = `${title} (${item.additionalInfo})`;
      }
      titles[item.timeStart.format(modifierKeyFormat)] = title;
    });
    return titles;
  };

  return (
    <>
      <ViewCard hideButtons={hideButtons} className={style.container}>
        <div className={style.avatarContainer}>
          <Avatar oid={oid} medium />
        </div>

        <Tab
          menu={{secondary: true, pointing: true}}
          panes={[
            {
              menuItem: {
                key: "info",
                content: msg.userTabDefault,
                icon: "info",
              },
              render: (): React.ReactNode => (
                <Tab.Pane>
                  <FieldLabel label={msg.employeeBusyDepartament}>
                    <FieldText value={data.curStaffOIDDepartOIDShortName} />
                  </FieldLabel>
                  <FieldLabel label={msg.employeeBusyExperience}>
                    <FieldInteger value={data.yearsWorked} />
                  </FieldLabel>
                  <FieldLabel label={msg.employeeBirthdate}>
                    <FieldText value={formatDate(data.birthDate)} />
                  </FieldLabel>
                  <FieldLabel label={msg.employeeBusyPost}>
                    <FieldText value={data.curStaffOIDPositionOIDName} />
                  </FieldLabel>
                  <FieldLabel label={msg.employeeBusyWorkstartdate}>
                    <FieldText value={formatDate(data.startWorkDate)} />
                  </FieldLabel>
                  <FieldLabel label={msg.userMyMobileNumber}>
                    <FieldPhone value={data.mobilePhone} />
                  </FieldLabel>
                </Tab.Pane>
              ),
            },
            {
              menuItem: {
                key: "contacts",
                content: msg.userTabContacts,
                icon: "phone",
              },
              render: (): React.ReactNode => (
                <Tab.Pane>
                  <FormContext {...methods}>
                    <Form onSubmit={methods.handleSubmit(handleFormContactsSubmit)}>
                      <FormField
                        label={msg.userPhoneForPasswordRecovery}
                        name="passwordRecoveryPhone"
                        defaultValue={data.passwordRecoveryPhone || ""}
                      />
                      <FormField
                        label={msg.userEmailForRemindings}
                        name="passwordRecoveryEmail"
                        defaultValue={data.passwordRecoveryEmail || ""}
                      />
                      <FormField
                        label={msg.userPhoneForKP}
                        name="phone"
                        defaultValue={data.contactOIDMobilePhone || ""}
                      />
                      <FormField label={msg.userEmailForKP} name="email" defaultValue={data.eMail || ""} />

                      <Button primary type="submit">
                        {msg.saveButton}
                      </Button>

                      {errorText && (
                        <Message onDismiss={handleDismiss} negative hidden={!displayMessage}>
                          {msg[errorText] || errorText}
                        </Message>
                      )}
                      {successText && (
                        <Message onDismiss={handleDismiss} positive hidden={!displayMessage}>
                          {msg[successText] || successText}
                        </Message>
                      )}
                      <>{displayMessage}</>
                    </Form>
                  </FormContext>
                </Tab.Pane>
              ),
            },
            {
              menuItem: {
                key: "schedule",
                content: msg.userTabSchedule,
                icon: "calendar outline",
              },
              render: (): React.ReactNode => {
                const titles = prepareTitles(schedule);
                return (
                  <Tab.Pane>
                    <DayPicker
                      className={style.schedule}
                      onMonthChange={handleMonthChange}
                      modifiers={prepareModifiers(schedule)}
                      modifiersStyles={prepareModifierStyles(schedule)}
                      renderDay={(date): React.ReactNode => {
                        const key = moment(date).format(modifierKeyFormat);
                        const day = <div className={style.scheduleDay}>{date.getDate()}</div>;
                        if (!titles[key]) {
                          return day;
                        }
                        return <Popup content={titles[key]} trigger={day} />;
                      }}
                    />
                  </Tab.Pane>
                );
              },
            },
            // {
            //   menuItem: {
            //     content: msg.userTabSettings,
            //     icon: "setting",
            //   },
            //   render: (): React.ReactNode => (
            //     <Tab.Pane>
            //       <div>@todo Настройки</div>
            //     </Tab.Pane>
            //   ),
            // },
          ]}
        />
      </ViewCard>
    </>
  );
};
UserViewCard.defaultProps = UserViewCardDefaultProps;

export default UserViewCard;
