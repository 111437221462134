import React from "react";
import {useTheme} from "utils/hooks";
import styles from "./home-style";
import ButtonMenu from "../menu/button-menu";
import {menu} from "../menu";

type THomeProps = {} & typeof HomeDefaultProps;
const HomeDefaultProps = {};

const Home = (props: THomeProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];

  return (
    <div className={style.container}>
      <ButtonMenu menu={menu} />
    </div>
  );
};
Home.defaultProps = HomeDefaultProps;

export default Home;
