/* eslint-disable import/prefer-default-export */

import React from "react";
import cn from "classnames";
import {MenuItem as MenuItemBase, StrictMenuItemProps} from "semantic-ui-react";
import {useTheme} from "utils/hooks";
import styles from "./menu-item-style";

export type TMenuItemProps = StrictMenuItemProps;

export const MenuItem = (props: TMenuItemProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];
  const {className, ...rest} = props;

  return <MenuItemBase className={cn(style.control, className)} {...rest} />;
};
