import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isTaskListInit, getTaskListActualItems, isTaskListLoading} from "selectors/task";
import {fetchTaskList} from "actions/thunk";
import {useMessages} from "utils/messages";
import {Loader} from "components/ui/element/loader";
import PanelContainer from "components/ui/element/panel-container";
import TaskListFilter from "components/task/task-list-filter";
import {TaskFilterContextProvider, withFilteredTaskList} from "components/task/task-list-filter/task-filter-context";
import TaskListActual from "./task-list-actual";

type TTaskCurrentListContainerProps = {
  filterClassName?: string;
};

const FilteredTaskListActual = withFilteredTaskList(TaskListActual);

const TaskCurrentListContainer = ({filterClassName}: TTaskCurrentListContainerProps): React.ReactElement => {
  const dispatch = useDispatch();
  const isInit = useSelector(isTaskListInit);
  const isLoading = useSelector(isTaskListLoading);
  const items = useSelector(getTaskListActualItems);
  const {taskListActual} = useMessages();

  useEffect(() => {
    if (isInit || isLoading) {
      return;
    }
    dispatch(fetchTaskList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <PanelContainer title={taskListActual}>
      <TaskFilterContextProvider>
        <TaskListFilter className={filterClassName} />
        <FilteredTaskListActual items={items} />
      </TaskFilterContextProvider>
    </PanelContainer>
  );
};

export default TaskCurrentListContainer;
