import React from "react";
import {useTheme} from "utils/hooks";
import styles from "./panel-container-style";

type TPanelContainerProps = {
  title?: string | React.ReactElement;
  children?: React.ReactNode;
};

const PanelContainer = ({title, children}: TPanelContainerProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];

  return (
    <div className={style.panelContainer}>
      {title && <div className={style.panelTitle}>{title}</div>}
      {children}
    </div>
  );
};

export default PanelContainer;
