import {BaseApi} from "api/common";
import {TFileType} from "types/api/fileType";
import {mapResponse} from "utils/helpers";

type TTaskFileTypeData = {
  Code: string;
  Name: string;
  OID: number;
};

class DictApi extends BaseApi {
  async getFileTypes(): Promise<TFileType[]> {
    return this.fetchApi<TTaskFileTypeData[], TFileType[]>({
      url: "/MASP/MSV2/File/ReadType",
      mapFn: (data): TFileType[] => data.map((item) => mapResponse(item)),
    });
  }
}

const dictApi = new DictApi();

export default dictApi;
