import {BaseApi} from "api/common";
import {
  TCurrentUser,
  TCurrentUserCommon,
  TCurrentUserToken,
  TCurrentUserInstance,
  TUserMenuItem,
  TScheduleItem,
} from "types/api/currentUser";
import {mapResponse} from "utils/helpers";
import moment from "moment";

type TCurrentUserTokenResponse = {
  access_token: string;
  life_time_minutes: number;
};

type TCurrentUserResponse = {
  BarCode: string;
  BirthDate: string;
  CountActiveContact: number;
  CountActiveTask: number;
  CountUnreadMessage: number;
  DepartOID: number;
  DepartOIDCalcName: string;
  DepartOIDShortName: string;
  Email: string;
  EmployeeOID: number;
  FirstName: string;
  HrBusiness?: {
    OID: number;
    Name: string;
  };
  OID: number;
  IsSystemUser: boolean;
  KPEmail: string;
  KPPhone: string;
  LastDateUpdate?: string;
  LastName: string;
  MobilePhone: string;
  NearestSheduleInfo?: string;
  Petronimic: string;
  Phones: [
    {
      readonly Number: string;
      readonly TypeCode: string;
      readonly TypeName: string;
      readonly Extension: string;
    }
  ];
  Position: string;
  RecoveryPhone?: string;
  StatusCode: string;
  StatusColorHEX: string;
  StatusName: string;
  UserID: number;
  UserKey: string;
  YearsWorked: number;
};

type TScheduleResponse = {
  Days: {
    TimeStart: string;
    TimeEnd: string;
    WorkHours: string;
    Code: string;
    AdditionalInfo: string | null;
    IsWork: boolean;
    Color: string;
    ColorText: string;
  }[];
};

class UserApi extends BaseApi {
  async getCurrentUser(): Promise<TCurrentUser> {
    return this.fetchApi<TCurrentUserResponse, TCurrentUser>({url: "/MASP/MSV2/Employee/ReadSelf", mapFn: mapResponse});
  }

  async getCurrentUserCommon(): Promise<TCurrentUserCommon> {
    return this.fetchApi({
      url: "/Common/UserCurrent",
    });
  }

  async hasAccess(path: string): Promise<void> {
    return this.fetchApi({
      url: "/Page/Access",
      params: {
        Path: path,
      },
    });
  }

  async getCurrentUserMenu(): Promise<TUserMenuItem[]> {
    return this.fetchApi({
      url: "/Common/ReadMenu",
    });
  }

  async getToken(login: string, password: string): Promise<TCurrentUserToken> {
    return this.fetchApi<TCurrentUserTokenResponse, TCurrentUserToken>({
      url: "/Auth/GetToken",
      params: {
        UserName: login,
        Password: password,
      },
      reqParams: {headers: undefined},
      mapFn: mapResponse,
    });
  }

  async get(): Promise<TCurrentUserInstance> {
    return this.fetchApi<object[][], TCurrentUserInstance>({
      url: "/MASP/MSV1/HrEmployee/ReadInstance",
      params: {},
      mapFn: (data) => mapResponse(data[0][0]),
    });
  }

  saveProfileContacts = async (
    phone: string,
    eMail: string,
    recoveryMobilePhone: string,
    passwordRecoveryEmail: string
  ): Promise<void> => {
    await this.fetchApi({
      url: "MASP/MSV1/HrEmployee/WriteOwn",
      params: {
        // eslint-disable-next-line @typescript-eslint/camelcase
        ContactOID_MobilePhone: phone,
        EMail: eMail,
        PasswordRecoveryPhone: recoveryMobilePhone,
        PasswordRecoveryEmail: passwordRecoveryEmail,
      },
    });
  };

  async getSchedule(employeeOID: number, startAt: moment.Moment, finishAt: moment.Moment): Promise<TScheduleItem[]> {
    return this.fetchApi<TScheduleResponse, TScheduleItem[]>({
      url: "/MASP/MSV2/Employee/ReadSchedule",
      params: {
        EmployeeOID: employeeOID,
        MonthStart: startAt.format("DD-MM-YYYY HH:mm:ss"),
        MonthEnd: finishAt.format("DD-MM-YYYY HH:mm:ss"),
      },
      mapFn: (data) => {
        return data
          ? data.Days.map((item) => {
              return {
                timeStart: moment(item.TimeStart),
                timeEnd: moment(item.TimeEnd),
                workHours: item.WorkHours,
                code: item.Code,
                additionalInfo: item.AdditionalInfo,
                isWork: item.IsWork,
                color: item.Color,
                colorText: item.Color,
              };
            })
          : [];
      },
    });
  }
}

const userApi = new UserApi();

export default userApi;
