import React from "react";
import {useTheme} from "utils/hooks";
import styles from "./field-phone-style";

type TFieldPhoneProps = {
  name?: string;
  label?: string;
  value?: string;
  code?: string;
} & typeof FieldPhoneDefaultProps;
const FieldPhoneDefaultProps = {
  value: "",
};

const FieldPhone = ({name, label, value, code}: TFieldPhoneProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];

  if (label === undefined) {
    return <span className={style.value}>{code === "Mob" ? <a href={`tel: ${value}`}>{value}</a> : <>{value}</>}</span>;
  }

  return (
    <div className={style.container}>
      <span>{label}</span>
      <span>{code === "Mob" ? <a href={`tel: ${value}`}>{value}</a> : <>{value}</>}</span>
    </div>
  );
};
FieldPhone.defaultProps = FieldPhoneDefaultProps;

export default FieldPhone;
