import React from "react";
import {InlineLoader} from "components/ui/element/loader";

const TaskList = React.lazy(() => import("components/task/task-list"));

const TaskListRoute = (): React.ReactElement => {
  return (
    <React.Suspense fallback={<InlineLoader />}>
      <TaskList />
    </React.Suspense>
  );
};

export default TaskListRoute;
