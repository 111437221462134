import React, {useEffect, useRef} from "react";
import cn from "classnames";
import Segment from "components/ui/element/segment";
import {dispatchEvent, dispatchEventAsync, addListener, removeListener, putResponse, TData} from "utils/desktopEvent";
import Grid from "components/ui/element/grid";
import {useTheme} from "utils/hooks";
import styles from "./view-card-style";

type ViewCardProps = {
  id?: number;
  children?: React.ReactNode;
  hideButtons?: boolean;
  className?: string;
} & typeof ViewCardDefaultProps;
const ViewCardDefaultProps = {
  id: 0,
};

const ViewCard = (props: ViewCardProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];
  const submitRef = useRef<HTMLButtonElement>(null);
  const {id, children, className} = props;

  // TODO: remove deprecated handler
  const handleOuterFormSubmitOld = (): void => {
    if (submitRef.current) {
      submitRef.current.click();
    }
  };

  const handleOuterFormSubmit = (event: string, data: TData, eventId?: string, eventReplyId?: string): void => {
    if (submitRef.current) {
      submitRef.current.click();
    }
    setTimeout(() => {
      if (eventId === undefined) {
        return;
      }
      putResponse(eventId, {ok: true});
    }, 5000);
  };

  // TODO: remove deprecated effect
  useEffect(() => {
    window.addEventListener("submitFormEvent", handleOuterFormSubmitOld);
    return (): void => window.removeEventListener("submitFormEvent", handleOuterFormSubmitOld);
  }, []);

  useEffect(() => {
    dispatchEvent("open_page", {oid: id});
    addListener(handleOuterFormSubmit);
    return (): void => removeListener(handleOuterFormSubmit);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function waitForResult(): Promise<void> {
      // eslint-disable-next-line no-console
      console.log("async result wait start");
      const result = await dispatchEventAsync("open_page_async", {oid: id}, "123123");
      // eslint-disable-next-line no-console
      console.log("async result", result);
    }
    waitForResult();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid className={cn(style.grid, className)} padded>
      <Grid.Column width={16}>
        <Segment>{children}</Segment>
      </Grid.Column>
    </Grid>
  );
};
ViewCard.defaultProps = ViewCardDefaultProps;

export default ViewCard;
