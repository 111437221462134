import React from "react";
import {InlineLoader} from "components/ui/element/loader";
import {TEST_API_URL} from "constants/common";

const MessagePage = React.lazy(() => import("components/message/messages-page"));

const MessageRoute = (): React.ReactElement => {
  return (
    <React.Suspense fallback={<InlineLoader />}>
      <MessagePage baseUrl={TEST_API_URL} showInput={false} />
    </React.Suspense>
  );
};

export default MessageRoute;
