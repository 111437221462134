/* eslint-disable class-methods-use-this */
import axios, {AxiosRequestConfig} from "axios";
import {isFunction} from "lodash-es";
import React, {useCallback, useMemo} from "react";
import {AsyncFulfilled, AsyncOptions, AsyncProps, AsyncState, useAsync} from "react-async";
import AsyncWrapper from "utils/async-wrapper";
import ErrorDialog from "components/ui/modal/error-dialog";
import {useDialog} from "components/ui/modal";
import {loadToken} from "reducers/auth";
import {API_BASIC_AUTH_PASSWORD, API_BASIC_AUTH_USERNAME, API_URL_BASE_PATH} from "constants/common";
import {TAbstractUser} from "types/api/common";

type ApiResponse<T> = {
  requestKey: string;
  error: {message: string} | null;
  data: T;
};

export type TNavData = {
  readonly TotalItems: number;
  readonly PageSize: number;
  readonly CurrentPage: number;
};

export type TAbstractUserData = {
  readonly Name: string;
  readonly Status: boolean;
  readonly StatusCode: string;
  readonly StatusName: string;
  readonly StatusColorHEX: string;
};

export type TUserData = {
  readonly OID: number;
} & TAbstractUser;

export type TPagingListPageResponse<T> = {
  Items: T[];
  Nav: TNavData;
};

type TBaseApiFetchParams<T, K> = {
  url: string;
  params?: {[key: string]: string | number | boolean} | FormData;
  reqParams?: AxiosRequestConfig;
  mapFn?: (result: T) => K;
};

class BaseApi {
  async fetch<T>(
    url: string,
    params: {[key: string]: string | number | boolean} | FormData = {},
    reqParams?: AxiosRequestConfig
  ): Promise<T> {
    const token = loadToken();
    const requestParams: AxiosRequestConfig = {
      method: "post",
      url,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      baseURL: API_URL_BASE_PATH,
      data: params,
      ...reqParams,
    };
    if (API_BASIC_AUTH_USERNAME && API_BASIC_AUTH_PASSWORD) {
      requestParams.auth = {
        username: API_BASIC_AUTH_USERNAME,
        password: API_BASIC_AUTH_PASSWORD,
      };
    }
    const response = await axios.request<T>(requestParams);
    return response.data;
  }

  async fetchApi<T, K = T>({url, params, reqParams, mapFn}: TBaseApiFetchParams<T, K>): Promise<K> {
    const response = await this.fetch<ApiResponse<T>>(url, params, reqParams);
    const {data, error} = response;
    if (error) {
      throw new Error(error.message);
    }
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return isFunction(mapFn) ? mapFn(data) : (data as any);
    } catch (err) {
      throw new Error("apiMappingError");
    }
  }

  async fetchBlob({url, params, reqParams}: TBaseApiFetchParams<void, void>): Promise<Blob> {
    return this.fetch<Blob>(url, params, {
      ...reqParams,
      responseType: "blob",
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isCancel(value: any): boolean {
    return axios.isCancel(value);
  }
}

const useAsyncApi = <T, P>(fn: (params: P) => Promise<T>, params: AsyncOptions<T>): AsyncState<T> => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const promiseFn = useCallback((props: AsyncProps<T>): Promise<T> => fn(props as P), []);
  return useAsync<T>({promiseFn, ...params});
};

export type TAsyncResult<T> = {
  data: T;
  asyncState: AsyncFulfilled<T>;
};

type ComponentParams<T, P> = Pick<P, Exclude<keyof P, keyof TAsyncResult<T>>>;

type TAsyncParams<T, P> = {
  persist?: boolean | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  watchProp?: (params: ComponentParams<T, P>) => any;
} & AsyncOptions<T>;

export const withAsync = <T, P>(
  Component: React.ComponentType<P>,
  fn: (params: ComponentParams<T, P>) => Promise<T>,
  params?: TAsyncParams<T, P>
) => (props: ComponentParams<T, P>): React.ReactElement => {
  const {persist, errorComponent, loaderComponent, watchProp, ...rest} = params || {};
  const state = useAsyncApi<T, P>(fn, {
    ...props,
    ...rest,
    ...(watchProp ? {watchFn: (p, prev): boolean => watchProp(p as P) !== watchProp(prev as P)} : {}),
  });
  return (
    <AsyncWrapper state={state} persist={persist} errorComponent={errorComponent} loaderComponent={loaderComponent}>
      {(data): React.ReactElement => (
        <Component data={data} asyncState={state as AsyncFulfilled<T>} {...(props as P)} />
      )}
    </AsyncWrapper>
  );
};

const getBlobResponseAsJson = <T,>(response: Blob): Promise<ApiResponse<T>> => {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    // eslint-disable-next-line func-names
    fr.onload = function(): void {
      resolve(JSON.parse(this.result as string));
    };
    // eslint-disable-next-line func-names
    fr.onerror = function(): void {
      reject(this.error);
    };
    fr.readAsText(response);
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useErrorDialog = <T extends (...args: any[]) => Promise<any> | undefined>(fn: T): T => {
  const {showDialog} = useDialog();
  return useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (async (...args: any[]): Promise<any> => {
        try {
          return await fn(...args);
        } catch (error) {
          let errorMessage: string | undefined;
          if (error.response) {
            let responseData = error.response?.data;
            if (error.response?.data instanceof Blob) {
              responseData = await getBlobResponseAsJson(error.response?.data);
            }
            errorMessage = responseData?.error?.message;
          } else {
            errorMessage = error.message;
          }
          showDialog(() => <ErrorDialog error={errorMessage} />);
          // throw error;
          return {};
        }
      }) as T,
    [showDialog, fn]
  );
};

export {BaseApi, useAsyncApi};
