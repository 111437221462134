import React from "react";
import FormField from "components/ui/form-field";
import {Form, Button, Message} from "semantic-ui-react";
import {FormContext, useForm} from "react-hook-form";
import {useTheme} from "utils/hooks";
import {useMessages} from "utils/messages";
import styles from "./theme.style";

export type AuthParams = {
  login: string;
  password: string;
};

export type LoginFormProps = {
  loginText?: string;
  passwordText?: string;
  loginButtonText?: string;
  errorText?: string;
  onSubmit?: (authParams: AuthParams) => void;
} & typeof LoginFormDefaultProps;
const LoginFormDefaultProps = {
  loginText: "Логин",
  passwordText: "Пароль",
  loginButtonText: "Войти",
};

const LoginForm = (props: LoginFormProps): React.ReactElement => {
  const {loginText, passwordText, loginButtonText, errorText, onSubmit} = props;
  const theme = useTheme();
  const methods = useForm<AuthParams>();
  const {handleSubmit} = methods;
  const style = styles[theme];
  const msg = useMessages();

  const handleFormSubmit = (data: AuthParams): void => {
    if (!onSubmit) {
      return;
    }
    onSubmit(data);
  };

  return (
    <div className={style.container}>
      <FormContext {...methods}>
        <Form onSubmit={handleSubmit(handleFormSubmit)}>
          <FormField name="login" simple placeholder={loginText} />
          <FormField name="password" inputType="password" simple placeholder={passwordText} />

          <Button primary type="submit" fluid>
            {loginButtonText}
          </Button>
          {errorText && <Message negative>{msg[errorText] || errorText}</Message>}
        </Form>
      </FormContext>
    </div>
  );
};
LoginForm.defaultProps = LoginFormDefaultProps;

export default LoginForm;
