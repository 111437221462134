/* eslint-disable import/prefer-default-export */

import React from "react";
import cn from "classnames";
import {HeaderSubheader as HeaderSubheaderBase, StrictHeaderSubheaderProps} from "semantic-ui-react";
import {useTheme} from "utils/hooks";
import styles from "./header-subheader-style";

export type THeaderSubheaderProps = StrictHeaderSubheaderProps;

export const HeaderSubheader = (props: THeaderSubheaderProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];
  const {className, ...rest} = props;

  return <HeaderSubheaderBase className={cn(style.control, className)} {...rest} />;
};
