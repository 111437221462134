import React, {createContext, useCallback, useContext, useState} from "react";
import {v4 as uuid} from "uuid";
import {PortalWithState} from "react-portal";

type TPortalState = {
  [key: string]: () => React.ReactNode;
};

const initialState = {} as TPortalState;

type TFuncInterface = {
  showDialog: (showFn: () => React.ReactNode) => void;
  hideDialog: () => void;
};

type TModalApi = [typeof initialState, TFuncInterface];

const ModalContext = createContext<TModalApi>([initialState, (null as unknown) as TFuncInterface]);

export const ModalProvider: React.FC = ({children}): React.ReactElement => {
  const [state, setState] = useState(initialState);
  const showDialog = useCallback((showFn: () => React.ReactNode): void => setState({...state, [uuid()]: showFn}), [
    state,
    setState,
  ]);
  const hideDialog = useCallback((): void => setState({} as TPortalState), [setState]);
  const value = [state, {showDialog, hideDialog}] as TModalApi;
  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};

export const useDialog = (): TFuncInterface => {
  const [, api] = useContext(ModalContext);
  return api;
};

export const ModalContainer = (): React.ReactElement => {
  const [state] = useContext(ModalContext);
  return (
    <>
      {Object.keys(state).map((key) => (
        <PortalWithState key={key}>{(): React.ReactElement => <>{state[key]()}</>}</PortalWithState>
      ))}
    </>
  );
};
