import React from "react";
import {useMessages} from "utils/messages";
import {Message} from "semantic-ui-react";
import {useTheme} from "utils/hooks";
import styles from "./page404-style";

type TPage404Props = {} & typeof Page404DefaultProps;
const Page404DefaultProps = {};

const Page404 = (props: TPage404Props): React.ReactElement => {
  const theme = useTheme();
  const messages = useMessages();
  const style = styles[theme];

  return (
    <div className={style.container}>
      <Message negative>{messages.pageNotFound}</Message>
    </div>
  );
};
Page404.defaultProps = Page404DefaultProps;

export default Page404;
