import React from "react";
import moment from "moment";
import {formatDateTime} from "utils/date";

type TDateFormatProps = {
  date: moment.MomentInput;
  className?: string;
  format?: string;
} & typeof DateFormatDefaultProps;
const DateFormatDefaultProps = {
  date: new Date() as moment.MomentInput,
};

const DateFormat = ({date, format, className}: TDateFormatProps): React.ReactElement => (
  <span className={className}>{formatDateTime(date, false, format)}</span>
);
DateFormat.defaultProps = DateFormatDefaultProps;

export default DateFormat;
