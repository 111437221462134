import React from "react";
import {InlineLoader} from "components/ui/element/loader";

const MessagePage = React.lazy(() => import("components/message/messages-page"));

const MessageRoute = (): React.ReactElement => {
  return (
    <React.Suspense fallback={<InlineLoader />}>
      <MessagePage showInput />
    </React.Suspense>
  );
};

export default MessageRoute;
