import produce from "immer";
import {Action, createReducer} from "typesafe-actions";
import {
  tokenReceived,
  tokenExpired,
  tokenFailed,
  userInfoRequested,
  userInfoReceived,
  userInfoFailed,
  gatewayChanged,
} from "actions";
import {TCurrentUser, TCurrentUserCommon, TUserMenuItem} from "types/api/currentUser";

const TOKEN_KEY = "AUTH_TOKEN";
const EMPTY_TOKEN = "";

const saveToken = (token: string): void => localStorage.setItem(TOKEN_KEY, token);
export const loadToken = (): string => localStorage.getItem(TOKEN_KEY) || EMPTY_TOKEN;

const GATEWAY_KEY = "CHATBOT_GATEWAY";
export const saveGateway = (gatewayId: string): void => localStorage.setItem(GATEWAY_KEY, gatewayId);
export const getGateway = (): string | undefined => localStorage.getItem(GATEWAY_KEY) || undefined;

const urlParams = new URLSearchParams(window.location.search);
const urlToken = urlParams.get("token");
if (urlToken) {
  saveToken(urlToken);
}

export interface AuthState {
  readonly token: string;
  readonly gatewayId?: string;
  readonly errorText?: string;
  readonly isLoadingUserInfo: boolean;
  readonly isLoadedUserInfo: boolean;
  readonly currentUser?: TCurrentUser;
  readonly commonInfo?: TCurrentUserCommon;
  readonly menu?: TUserMenuItem[];
}

const initialState: AuthState = {
  token: loadToken(),
  gatewayId: getGateway(),
  isLoadingUserInfo: false,
  isLoadedUserInfo: false,
};

export default createReducer<AuthState, Action>(initialState)
  .handleAction(userInfoRequested, (state) => {
    return produce(state, (draft) => {
      draft.isLoadingUserInfo = true;
    });
  })
  .handleAction(userInfoReceived, (state, {payload}) => {
    return produce(state, (draft) => {
      draft.isLoadingUserInfo = false;
      const {userInfo, commonInfo, menu} = payload;
      draft.currentUser = userInfo;
      draft.commonInfo = commonInfo;
      draft.menu = menu;
      draft.isLoadedUserInfo = true;
      delete draft.errorText;
    });
  })
  .handleAction(userInfoFailed, (state, {payload: {message}}) => {
    return produce(state, (draft) => {
      draft.isLoadingUserInfo = false;
      draft.isLoadedUserInfo = false;
      draft.errorText = message;
      draft.token = EMPTY_TOKEN;
      saveToken(EMPTY_TOKEN);
    });
  })
  .handleAction(tokenReceived, (state, {payload}) => {
    const {token} = payload;
    saveToken(token);
    return produce(state, (draft) => {
      draft.token = token;
      delete draft.errorText;
    });
  })
  .handleAction(tokenExpired, (state) => {
    saveToken(EMPTY_TOKEN);
    return produce(state, (draft) => {
      draft.token = EMPTY_TOKEN;
      draft.gatewayId = undefined;
      draft.isLoadingUserInfo = false;
      draft.isLoadedUserInfo = false;
      draft.currentUser = undefined;
      draft.commonInfo = undefined;
      draft.menu = undefined;
    });
  })
  .handleAction(tokenFailed, (state, {payload: {message}}) => {
    return produce(state, (draft) => {
      draft.errorText = message;
    });
  })
  .handleAction(gatewayChanged, (state, {payload}) => {
    return produce(state, (draft) => {
      saveGateway(payload);
      draft.gatewayId = payload;
    });
  });
