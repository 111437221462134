import {Action} from "typesafe-actions";
import {RootState} from "reducers";
import {themeChanged} from "actions";
import {getTheme} from "selectors/app";
import {THEMES} from "constants/theme";

const KEYS = {
  F2: "F2",
};

export default (state: RootState, keyboardEvent: KeyboardEvent): Action | null => {
  // switch theme
  if (keyboardEvent.key === KEYS.F2) {
    const themeCurrent = getTheme(state) || THEMES[0];
    const currentIndex = THEMES.findIndex((theme) => theme === themeCurrent);
    const nextIndex = currentIndex < THEMES.length - 1 ? currentIndex + 1 : 0;
    return themeChanged(THEMES[nextIndex]);
  }

  return null;
};
