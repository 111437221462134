import React from "react";
import CN from "classnames";
import {Menu} from "components/ui/element/menu";
import {useDispatch, useSelector} from "react-redux";
import {useTheme} from "utils/hooks";
import {getEmployeeOID, getFullName} from "selectors/auth";
import {UserName} from "components/common/avatar";
import {SemanticICONS} from "semantic-ui-react";
import {getLeftSidebar, getRightSidebar} from "selectors/ui";
import IconButton from "components/ui/control/icon-button";
import {leftSidebarChanged, rightSidebarChanged} from "actions";
import {useIsMobile, useLeftSidebarType, useRightSidebarType} from "layouts/main/context";
import {partialRight} from "lodash-es";
import {TLeftSidebar, TRightSidebar} from "types/ui";
import styles from "./top-menu-style";

type TTopMenuIconProps = {
  name: SemanticICONS;
  active: boolean;
  onClick?: () => void;
} & typeof TopMenuIconDefaultProps;
const TopMenuIconDefaultProps = {
  active: false,
};

const TopMenuIcon = ({onClick, name, active}: TTopMenuIconProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];
  return (
    <IconButton className={CN(style.icon, {[style.activeIcon]: active})} icon={name} size="large" onClick={onClick} />
  );
};
TopMenuIcon.defaultProps = TopMenuIconDefaultProps;

const toggled = <T extends string>(oldValue?: T, newValue?: T): T | undefined => {
  return oldValue === newValue ? undefined : newValue;
};

const leftToggle = partialRight<TLeftSidebar | undefined, TLeftSidebar | undefined, TLeftSidebar | undefined>(
  toggled,
  "basic"
);

const rightToggle = partialRight<TRightSidebar | undefined, TRightSidebar | undefined, TRightSidebar | undefined>(
  toggled,
  "actualTasks"
);

const TopMenu = (): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];
  const oid = useSelector(getEmployeeOID);
  const fullName = useSelector(getFullName);
  const leftSidebar = useSelector(getLeftSidebar);
  const rightSidebar = useSelector(getRightSidebar);
  const {isMobile, sidebarMobileState, setSidebarMobileState} = useIsMobile();
  const leftSidebarType = useLeftSidebarType();
  const rightSidebarType = useRightSidebarType();
  const dispatch = useDispatch();

  const handleLeftToggle = (): void => {
    if (isMobile) {
      setSidebarMobileState({right: undefined, left: leftToggle(sidebarMobileState.left)});
    } else {
      dispatch(leftSidebarChanged(leftToggle(leftSidebar)));
    }
  };

  const handleRightToggle = (): void => {
    if (isMobile) {
      setSidebarMobileState({
        left: undefined,
        right: rightToggle(sidebarMobileState.right),
      });
    } else {
      dispatch(rightSidebarChanged(rightToggle(rightSidebar)));
    }
  };

  return (
    <Menu color="blue" className={style.container} inverted>
      <Menu.Item>
        <TopMenuIcon name="list ul" active={!!leftSidebarType} onClick={handleLeftToggle} />
      </Menu.Item>
      {/* <Menu.Item>
        <div className={style.icon}>
          <Icon name="users" size="large" />
        </div>
      </Menu.Item> */}

      <Menu.Item position="right" />

      <Menu.Item name="features">
        <UserName oid={oid} name={fullName} className={style.user} asUrl employeeComponentName="UserViewCard" />
      </Menu.Item>
      <Menu.Item>
        <div className={style.icon}>
          <TopMenuIcon name="clipboard list" active={!!rightSidebarType} onClick={handleRightToggle} />
        </div>
        {/* <div className={style.icon}>
          <Icon name="bars" size="large" />
        </div> */}
      </Menu.Item>
    </Menu>
  );
};

export default TopMenu;
