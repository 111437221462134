import React from "react";
import cn from "classnames";
import {Grid as BaseGrid, StrictGridProps} from "semantic-ui-react";
import {useTheme} from "utils/hooks";
import GridColumn from "./grid-column";
import GridRow from "./grid-row";
import styles from "./grid-style";

export type TGridProps = StrictGridProps;

const Grid = (props: TGridProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];
  const {className, ...rest} = props;

  return <BaseGrid className={cn(style.control, className)} {...rest} />;
};

Grid.Column = GridColumn;
Grid.Row = GridRow;

export default Grid;
