import React from "react";
import cn from "classnames";
import {GridRow as BaseGridRow, StrictGridRowProps} from "semantic-ui-react";
import {useTheme} from "utils/hooks";
import styles from "./grid-row-style";

export type TGridRowProps = StrictGridRowProps;

const GridRow = (props: TGridRowProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];
  const {className, ...rest} = props;

  return <BaseGridRow className={cn(style.control, className)} {...rest} />;
};

export default GridRow;
