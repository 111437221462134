import produce from "immer";
import {Action, createReducer} from "typesafe-actions";
import {TGroup, TTaskData} from "types/api/taskActual";
import {taskListReceived, taskListRequested} from "actions";

type TaskListState = {
  // readonly byId: object;
  // readonly ids: number[];
  readonly tasks: TTaskData[];
  readonly groups: TGroup[];
  readonly isInit: boolean;
  readonly isLoading: boolean;
};

const initialState: TaskListState = {
  // byId: {},
  // ids: [],
  tasks: [],
  groups: [],
  isInit: false,
  isLoading: false,
};

export default createReducer<TaskListState, Action>(initialState)
  .handleAction(taskListRequested, (state) => {
    return produce(state, (draft) => {
      draft.isLoading = true;
    });
  })
  .handleAction(taskListReceived, (state, {payload}) => {
    const {Tasks: tasks, Groups: groups} = payload;
    return produce(state, (draft) => {
      draft.tasks = tasks;
      draft.groups = groups;
      draft.isInit = true;
      draft.isLoading = false;
    });
  });
