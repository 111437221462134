import React from "react";
import {Message} from "semantic-ui-react";
import {Loader} from "components/ui/element/loader";
import {
  IfPending,
  IfFulfilled,
  IfRejected,
  FulfilledChildren,
  AsyncInitial,
  AbstractState,
  AsyncPending,
  AsyncRejected,
  AsyncFulfilled,
} from "react-async";
import {useMessages} from "utils/messages";

const AsyncWrapper = <T extends {}>({
  children,
  persist,
  state,
  errorComponent,
  loaderComponent,
}: {
  children?: FulfilledChildren<T>;
  persist?: boolean | undefined;
  state:
    | AsyncInitial<T, AbstractState<T>>
    | AsyncPending<T, AbstractState<T>>
    | AsyncFulfilled<T, AbstractState<T>>
    | AsyncRejected<T, AbstractState<T>>;
  errorComponent?: (error: Error) => React.ReactElement;
  loaderComponent?: () => React.ReactElement;
}): JSX.Element => {
  const messages = useMessages();
  return (
    <>
      <IfPending state={state}>{loaderComponent ? loaderComponent() : <Loader />}</IfPending>
      <IfRejected state={state}>
        {(error): React.ReactElement => {
          return errorComponent ? (
            errorComponent(error)
          ) : (
            <Message style={{margin: 15}} negative>
              {messages[error.message] || error.message}
            </Message>
          );
        }}
      </IfRejected>
      <IfFulfilled state={state} persist={persist}>
        {children}
      </IfFulfilled>
    </>
  );
};

export default AsyncWrapper;
