import React from "react";
import cn from "classnames";
import {useTheme} from "utils/hooks";
import dataloader from "simple-data-loader";
import employeeApi from "api/employee";
import {TAsyncResult, withAsync} from "api/common";
import styles from "./avatar-style";

type TAvatarImageProps = {
  oid: number;
} & TAsyncResult<string | undefined>;
type TAvatarProps = {
  oid: number;
  medium?: boolean;
  boxy?: boolean;
} & typeof AvatarDefaultProps;
const AvatarDefaultProps = {};

const emptyComponent = (): React.ReactElement => <span />;

const fetchData = async (oid: number): Promise<string | undefined> => {
  try {
    if (!oid) {
      return undefined;
    }
    const response = await employeeApi.getAvatar(oid);
    const blob = new Blob([response as BlobPart], {type: "image/*"});
    return window.URL.createObjectURL(blob);
  } catch (error) {
    return undefined;
  }
};

const avatarLoader = dataloader(fetchData, {ttl: 10000});

const AvatarImage = ({data}: TAvatarImageProps): React.ReactElement => (
  <span style={{backgroundImage: data ? `url('${data}')` : "none"}} />
);

const AvatarImageWithAsync = withAsync(AvatarImage, ({oid}) => avatarLoader(oid), {
  errorComponent: emptyComponent,
  loaderComponent: emptyComponent,
  watchProp: ({oid}) => oid,
});

const Avatar = ({oid, medium, boxy}: TAvatarProps): React.ReactElement => {
  const theme = useTheme();
  const style = styles[theme];
  return (
    <div className={cn(style.container, medium ? style.medium : null, boxy ? style.boxy : null)}>
      <AvatarImageWithAsync oid={oid} />
    </div>
  );
};
Avatar.defaultProps = AvatarDefaultProps;

export default Avatar;
