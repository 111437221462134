import {withAsync} from "api/common";
import BaseDialog, {TDialogProps} from "components/ui/modal/base-dialog/base-dialog";
import React from "react";
import {useDialog} from "components/ui/modal";
import {useMessages} from "utils/messages";
import EmployeeViewCard from "components/section/employee/employee-view/employee-view-card";
import UserViewCard from "components/section/user/user-view-card";
import employeeApi from "api/employee";
import userApi from "api/user";

export type TEmployeeComponentName = "EmployeeViewCard" | "UserViewCard";

type TEmployeeViewDialogProps = {
  oid: number;
  componentName: TEmployeeComponentName;
} & TDialogProps;

const EmployeeViewContent = withAsync(EmployeeViewCard, ({oid}) => employeeApi.get(oid), {
  watchProp: ({oid}) => oid,
});
const UserViewContent = withAsync(UserViewCard, () => userApi.get());

const components = {EmployeeViewCard: EmployeeViewContent, UserViewCard: UserViewContent};

const EmployeeViewDialog = ({oid, componentName}: TEmployeeViewDialogProps): React.ReactElement => {
  const {hideDialog} = useDialog();
  const msg = useMessages();
  const {okButton} = msg;
  const ViewComponent = components[componentName];

  return (
    <BaseDialog
      content={<ViewComponent oid={oid} onClickLinkOutside={hideDialog} />}
      actions={[{key: "ok", content: okButton, positive: true, onClick: hideDialog}]}
    />
  );
};

export default EmployeeViewDialog;
