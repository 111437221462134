/* eslint-disable import/prefer-default-export */

import React from "react";
import {Dimmer} from "semantic-ui-react";
import {InlineLoader} from "components/ui/element/loader/inline-loader";

export const Loader = (): React.ReactElement => {
  return (
    <Dimmer inverted active>
      <InlineLoader />
    </Dimmer>
  );
};
