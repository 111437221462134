import React, {createContext, useCallback, useContext, useMemo, useReducer} from "react";
import {get, includes} from "lodash-es";
import {TTask} from "types/api/task";

type TAction = {type: string; payload?: object};

type TFilter = {
  filter: string;
  showFilters: boolean;
  groupName?: string;
  status?: number;
};

const UPDATE_ACTION = "UPDATE_ACTION";
const TOGGLE_FILTER = "TOGGLE_FILTER";

export const initialState: TFilter = {
  filter: "",
  showFilters: false,
};

type TFuncInterface = {
  setFilter: (value: Partial<TFilter>) => void;
  toggleFilter: () => void;
  setGroup: (groupName?: string) => void;
  setStatus: (status?: number) => void;
};

type TReducer = (state: TFilter, action: TAction) => TFilter;
type TFilterApi = [typeof initialState, TFuncInterface];

export const taskFilterReducer: TReducer = (state, {type, payload}) => {
  switch (type) {
    case UPDATE_ACTION:
      return {
        ...state,
        ...payload,
      };
    case TOGGLE_FILTER:
      return {
        ...state,
        showFilters: !state.showFilters,
      };
    default:
      return state;
  }
};

export const TaskFilterContext = createContext<TFilterApi>([initialState, (null as unknown) as TFuncInterface]);

export const TaskFilterContextProvider: React.FC = ({children}): React.ReactElement => {
  const [state, dispatch] = useReducer(taskFilterReducer, initialState);
  const setFilter = useCallback(
    (filter: Partial<TFilter>): void => dispatch({type: UPDATE_ACTION, payload: filter}),
    []
  );
  const toggleFilter = useCallback((): void => dispatch({type: TOGGLE_FILTER}), []);
  const setGroup = useCallback(
    (groupName: string): void => dispatch({type: UPDATE_ACTION, payload: {groupName, status: undefined}}),
    []
  );
  const setStatus = useCallback((status: number): void => dispatch({type: UPDATE_ACTION, payload: {status}}), []);
  const value = [state, {setFilter, toggleFilter, setGroup, setStatus}] as TFilterApi;
  return <TaskFilterContext.Provider value={value}>{children}</TaskFilterContext.Provider>;
};

type TFilteredTaskListProps = {
  items: TTask[];
};

const isTaskMatchFilter = (item: TTask, filter: string): boolean => {
  const filteredFields = ["caption", "subCaption", "creator.name", "id", "groupName", "statusName"];
  const itemValues = filteredFields.map((field) => get(item, field));
  return itemValues.some((value) => value && includes(value.toString().toLowerCase(), filter.toLowerCase()));
};

export const withFilteredTaskList = (Component: React.ComponentType<TFilteredTaskListProps>) => ({
  items,
}: TFilteredTaskListProps): React.ReactElement<TFilteredTaskListProps> => {
  const [state] = useContext(TaskFilterContext);
  const filteredItems = useMemo(
    () => (list: TTask[]): TTask[] =>
      list.filter(
        (item) =>
          (!state.filter || isTaskMatchFilter(item, state.filter)) &&
          (!state.groupName || item.groupName === state.groupName) &&
          (!state.status || item.statusOID === state.status)
      ),
    [state]
  );

  return <Component items={filteredItems(items)} />;
};
