import React from "react";
import {Tab} from "semantic-ui-react";
import {InlineLoader} from "components/ui/element/loader";
import {useMessages} from "utils/messages";

const TaskEdit = React.lazy(() => import("components/task/task-edit"));
// const TaskStatusHistory = React.lazy(() => import("components/task/task-view/task-status-history"));

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const createPanes = (messages: ReturnType<typeof useMessages>) => [
  {
    menuItem: messages.taskDefaultTab,
    render: (): React.ReactNode => (
      <Tab.Pane>
        <React.Suspense fallback={<InlineLoader />}>
          <TaskEdit />
        </React.Suspense>
      </Tab.Pane>
    ),
  },
  // {
  //   menuItem: messages.taskStatusTab,
  //   render: (): React.ReactNode => (
  //     <Tab.Pane>
  //       <React.Suspense fallback={<Loader />}>
  //         <TaskStatusHistory />
  //       </React.Suspense>
  //     </Tab.Pane>
  //   ),
  // },
];

const TaskEditPage = (): React.ReactElement => {
  const messages = useMessages();
  return (
    // <Main>
    <Tab menu={{secondary: true, pointing: true}} panes={createPanes(messages)} />
    // </Main>
  );
};

export default TaskEditPage;
