import React, {useState, useEffect} from "react";
import {useLocation, useHistory, Switch} from "react-router-dom";
import {useSelector} from "react-redux";
import {isLogged} from "selectors/auth";
import {usePrevious} from "utils/hooks";
import ProtectedRoute from "components/protected-route";
import Employee from "routes/employee";
import EmployeeView from "routes/employee/oid/view";
import {
  toPath,
  homeUrl,
  loginUrl,
  logoutUrl,
  taskListUrl,
  employeeListUrl,
  taskCurrentListUrl,
  taskViewUrl,
  employeeViewUrl,
  clientsUrl,
} from "utils/url-builder";
// import Home from "routes/home";
import Login from "routes/login";
import Logout from "routes/logout";
import IframeRoute from "routes/iframe";
import MessageRoute from "routes/message";
import ClientHistoryRoute from "routes/client-history";
import ClientsRoute from "routes/clients";
import ChatbotRoute from "routes/chatbot";
import TaskList from "routes/task/list";
import EmployeeList from "routes/employee/list";
import TaskCurrentList from "routes/task/current-list";
import TaskView from "routes/task/oid/view";
import TaskEdit from "routes/task/oid/edit";
import TaskExample from "routes/task-example";
import AuthLayout from "layouts/auth-layout";
import Main from "layouts/main";
import {ModalContainer} from "components/ui/modal";
import "./app.css";
import "semantic-ui-css/semantic.min.css";
import Home from "components/section/home";
import Page404 from "components/section/error/page404";
import {PARTNER_V1_PATH} from "constants/common";
import {useIsMobile} from "layouts/main/context";

const App = (): React.ReactElement => {
  const logged = useSelector(isLogged);
  const prevousLogged = usePrevious(logged);
  const location = useLocation();
  const history = useHistory();
  const [initialUrl, setInitialUrl] = useState<string>();
  const {isMobile} = useIsMobile();

  useEffect(() => {
    if (!logged && !initialUrl && location.pathname !== logoutUrl()) {
      setInitialUrl(`${location.pathname}${location.search}`);
    }

    if (!prevousLogged && logged && initialUrl) {
      history.push(initialUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logged]);

  return (
    <>
      <ModalContainer />
      <Switch>
        <ProtectedRoute
          exact
          path={toPath(loginUrl())}
          allowed={!logged}
          redirectToUrl={isMobile ? homeUrl() : taskListUrl()}
        >
          <AuthLayout>
            <Login />
          </AuthLayout>
        </ProtectedRoute>

        <ProtectedRoute path={toPath(logoutUrl())} allowed={logged} redirectToUrl={loginUrl()}>
          <Logout />
        </ProtectedRoute>
        <ProtectedRoute exact path={toPath(homeUrl())} allowed={logged} redirectToUrl={loginUrl()}>
          <Main>
            <Home />
          </Main>
        </ProtectedRoute>

        <ProtectedRoute path={toPath(employeeListUrl())} allowed={logged} redirectToUrl={loginUrl()}>
          <Main>
            <EmployeeList />
          </Main>
        </ProtectedRoute>
        <ProtectedRoute
          path={toPath(employeeViewUrl())}
          allowed={logged}
          redirectToUrl={loginUrl()}
          userMenuId="common/contacts"
        >
          <Main>
            <EmployeeView />
          </Main>
        </ProtectedRoute>
        <ProtectedRoute path="/employee/:id" allowed={logged} redirectToUrl={loginUrl()} userMenuId="common/contacts">
          <Main>
            <Employee />
          </Main>
        </ProtectedRoute>

        <ProtectedRoute path={toPath(taskListUrl())} allowed={logged} redirectToUrl={loginUrl()}>
          <Main>
            <TaskList />
          </Main>
        </ProtectedRoute>
        <ProtectedRoute path={toPath(taskCurrentListUrl())} allowed={logged} redirectToUrl={loginUrl()}>
          <Main>
            <TaskCurrentList />
          </Main>
        </ProtectedRoute>
        <ProtectedRoute path={toPath(taskViewUrl())} allowed={logged} redirectToUrl={loginUrl()} userMenuId="tasks">
          <Main>
            <TaskView />
          </Main>
        </ProtectedRoute>
        <ProtectedRoute path="/task/:oid/edit" allowed={logged} redirectToUrl={loginUrl()} userMenuId="tasks">
          <Main>
            <TaskEdit />
          </Main>
        </ProtectedRoute>
        <ProtectedRoute path="/task-example" allowed={logged} redirectToUrl={loginUrl()}>
          <Main>
            <TaskExample />
          </Main>
        </ProtectedRoute>
        <ProtectedRoute
          path="/chatbot/:oid?/(page)?/:page?/(message)?/:messageId?"
          allowed={logged}
          redirectToUrl={loginUrl()}
        >
          <Main>
            <ChatbotRoute />
          </Main>
        </ProtectedRoute>
        <ProtectedRoute
          path="/message/:oid?/(page)?/:page?/(message)?/:messageId?"
          allowed={logged}
          redirectToUrl={loginUrl()}
        >
          <Main>
            <MessageRoute />
          </Main>
        </ProtectedRoute>
        <ProtectedRoute
          path="/client-history/:oid?/(page)?/:page?/(message)?/:messageId?"
          allowed={logged}
          redirectToUrl={loginUrl()}
        >
          <Main>
            <ClientHistoryRoute />
          </Main>
        </ProtectedRoute>
        <ProtectedRoute path={toPath(clientsUrl())} allowed={logged} redirectToUrl={loginUrl()} userMenuId="tasks">
          <Main>
            <ClientsRoute />
          </Main>
        </ProtectedRoute>
        <ProtectedRoute path={PARTNER_V1_PATH} allowed={logged} redirectToUrl={loginUrl()}>
          <Main>
            <IframeRoute />
          </Main>
        </ProtectedRoute>
        <ProtectedRoute allowed={logged} redirectToUrl={loginUrl()}>
          <Main>
            <Page404 />
          </Main>
        </ProtectedRoute>
      </Switch>
    </>
  );
};

export default App;
